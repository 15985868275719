@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include desktop {
		flex-direction: row;
		width: 44%;
		padding: 24px 10px 24px 0;
		flex-shrink: 0;
	}

	.container {
		display: flex;
		position: relative;
	}

	.mobileContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 30px;

		@include desktop {
			display: none;
		}
	}
}
