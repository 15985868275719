@import "~/styles/mixins.scss";
.wrapper {
	padding: 0 16px;
	border: 1px solid $navy;
	min-width: 142px;
	background: $white;

	@include tablet {
		display: flex;
		border: none;
		padding: 0;
		min-width: auto;
	}
}
