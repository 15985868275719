@import "~/styles/mixins.scss";
.wrapper {
	.successWrapper {
		padding-top: 16px;

		@include tablet {
			padding: 0 24px 16px;
		}
	}

	.form {
		display: block;

		&.hidden {
			display: none;
		}

		.radio {
			margin-top: 0;
			padding: 16px 0;

			@include tablet {
				padding: 0 24px 16px;
			}

			.label {
				flex-basis: 100%;
				line-height: 16px;

				& * {
					font-size: 12px !important;
				}

				& + .label {
					margin-top: 16px;
				}
			}
		}
	}

	.checkIcon {
		height: 16px;
		margin-right: 8px;
		flex: none;
	}
}
