@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 15px;
	padding: 22px 16px;
	background-color: $black5;
	display: flex;
	justify-content: space-between;

	.link {
		color: $navy;
		display: flex;

		&:hover {
			text-decoration: underline;
		}

		.icon {
			margin-right: 9px;
		}
	}
}
