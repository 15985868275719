@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
	gap: 16px;
	margin-top: 4px;

	@include tablet {
		margin-top: 16px;
		gap: 32px;
	}

	@include small-desktop {
		flex-direction: row;
	}

	.skipContentList,
	.skipContentAnchor {
		position: absolute;
	}
}
