@import "~/styles/mixins.scss";
.wrapper {
	.control {
		margin: 0;
		width: 100%;
	}

	.addressNumbersControls {
		display: flex;

		.control:last-child {
			margin-left: 16px;
		}
	}

	.cityControls {
		align-items: flex-end;

		.control {
			margin: 0 0 24px;
		}

		@include tablet {
			display: flex;

			.control {
				margin: 0;

				&:last-child {
					margin-left: 16px;
				}
			}
		}
	}
}
