@import "~/styles/mixins.scss";
.wrapper {
	max-width: 530px;

	.description {
		font-size: 14px;
		line-height: 20px;
	}

	.buttons {
		margin-top: 32px;
		display: flex;
		gap: 16px;
		justify-content: space-between;
	}
}

.modal {
	overflow-y: auto;
}
