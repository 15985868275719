@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 100%;
	padding: 80px 16px;

	@include mobile {
		width: 561px;
		padding: 80px min(80px, 12vw);
		height: fit-content;
	}
}
