@import "~/styles/mixins.scss";
.wrapper {
	width: 104px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $black10;
	color: $navy;

	&.selected {
		background: $green;
		font-weight: 700;
		color: $white;
	}
}
