@import "~/styles/mixins.scss";
.wrapper {
	height: 44px;
	position: absolute;
	right: 16px;
	top: 0;
	font-size: 12px;
	line-height: 14px;
	color: $navy;
	white-space: pre-line;

	@include desktop {
		max-width: 70px;
	}

	&:hover {
		color: $green;
	}
}
