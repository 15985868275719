@import "~/styles/mixins.scss";
.wrapper {
	flex-shrink: 0;

	.icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		border: 2px solid $color-grey-600;
		border-radius: 100%;

		&.checked {
			border-width: 6px;
			border-color: $color-secondary-green-500;
		}
	}
}
