@import "~/styles/mixins.scss";
.wrapper {
	.link {
		display: inline-block;
		width: 100%;
		padding: 0 16px;

		&:hover {
			background: $navy-light;
		}
	}

	.title {
		font-size: 14px;
		color: $navy;
		line-height: 35px;

		@include tablet {
			line-height: 30px;
		}
	}
}
