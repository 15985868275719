@import "~/styles/mixins.scss";
.wrapper {
	position: absolute;
	margin: 0 16px;
	width: calc(100% - 32px);
	top: 40px;
	left: 0;
	background: $white;
	z-index: 3;

	@include desktop {
		width: 288px;
		margin: 0;
		top: 48px;
	}

	&.modal {
		position: relative;
		top: 4px;
		margin: 0;
		width: 100%;
	}
}
