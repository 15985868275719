@import "~/styles/mixins.scss";
.wrapper {
	margin-right: 10px;
	display: flex;
	flex-basis: 175px;
	align-items: center;
	position: relative;
	z-index: 2;

	&:hover {
		.label {
			color: $green-dark;
		}

		.icon {
			fill: $green-dark;
		}
	}

	&.white {
		&:hover {
			.label {
				color: $green-dark;
			}
		}

		.label {
			color: $white;
		}
	}

	&.navy {
		&:hover {
			.label {
				color: $green-dark;
			}
		}

		.label {
			color: $navy;
		}
	}

	.icon {
		transition: fill .2s ease;
	}

	.label {
		margin-left: 10px;
		font-size: 12px;
		line-height: 14px;
		text-align: left;
		display: flex;
		transition: color .2s ease;
		white-space: nowrap;
	}
}
