@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 50px;

	@include tablet {
		margin-top: $footer-margin-top;
		min-height: $footer-simple-min-height;
	}

	.footer {
		min-height: auto;
	}
}
