@import "~/styles/mixins.scss";
.wrapper {
	margin-left: 20px;

	@include desktop {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}
