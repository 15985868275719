@import "~/styles/mixins.scss";
.price {
	height: 56px;
	margin-top: 8px;
	overflow: hidden;

	@include mobile {
		height: 36px;
	}
}
