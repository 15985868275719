@import "~/styles/mixins.scss";
.button {
	width: 100%;
	padding-right: 8px !important;
	padding-left: 8px !important;
	font-size: 12px;
	white-space: normal;

	@include tablet {
		font-size: 16px;
	}
}
