@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 10px;

	.label {
		margin-bottom: 10px;
		font-size: 14px;
		background-color: $inpost-orange;
		padding: 5px;
		color: $black;
		display: inline-block;
	}

	.emptyLabel {
		height: 34px;
	}

	.widget {
		height: 500px;
	}
}
