@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	font-size: 14px;
	line-height: 15px;
	color: $black55;
	display: flex;
	align-items: center;

	@include tablet {
		font-size: 12px;
	}

	&.isMozaic {
		flex-direction: column;
	}

	.link {
		height: 40px;
		padding-right: 16px;
		padding-left: 16px;
		font-size: 14px;
		width: 115px;
		margin-left: 6px;

		@include tablet {
			font-size: 12px;
			margin-left: 12px;
			height: 26px;
			width: unset;
		}

		&.isMozaic {
			width: 100%;
			margin: 10px 0 0;
			font-size: 16px;
		}
	}
}
