@import "~/styles/mixins.scss";
.wrapper {
	.address {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.control {
			width: calc(50% - 8px);
		}
	}

	.postal {
		@include tablet {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.zip {
				max-width: 125px;
				margin-right: 16px;
			}

			.city {
				width: 100%;
			}
		}
	}
}
