@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	width: 100%;
	display: flex;
	flex-direction: column;

	.button {
		width: 100%;
	}

	.tooltip {
		width: 100%;
	}

	.link {
		margin-top: 16px;
		color: $navy;
		text-decoration: underline;
	}

	&.isMozaic {
		.link {
			color: $color-grey-999;
		}
	}
}
