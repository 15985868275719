@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;

	&.isMozaic {
		.text {
			color: $color-grey-900;
			text-decoration: underline;

			&:hover {
				color: $color-primary-01-600;
			}
		}

		.arrow {
			margin-left: 10px;
		}
	}

	.text {
		color: $navy;
		order: 1;

		&.reverse {
			order: 2;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.arrow {
		margin: 0 0 0 6px;
		flex-shrink: 0;
		order: 2;

		&.reverse {
			margin: 0 6px 0 0;
			transform: rotate(180deg);
			order: 1;
		}
	}
}
