@import "~/styles/mixins.scss";
.wrapper {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	width: 100%;
	border: solid 1px $black10;
	padding: 8px 16px;

	&.selected {
		position: relative;
		border: solid 1px $green;
		border-left-width: 5px;
		padding-left: 11px;
		z-index: 3;

		.couponValue {
			color: $navy;
		}
	}

	&.disabled {
		pointer-events: none;
	}

	.couponValue {
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		color: $black65;
	}
}
