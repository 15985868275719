@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;

	.label {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		flex-basis: 50%;
	}

	.error {
		flex-basis: 100%;
	}
}
