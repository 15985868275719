@import "~/styles/mixins.scss";
.wrapper {
	height: $header-simple-height-mobile;
	background: $gray95;
	z-index: 2;
	position: relative;

	@include desktop {
		height: $header-simple-height;
		border-bottom: none;
	}

	.content {
		padding-left: 138px;
		flex-direction: row;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: $header-simple-height-mobile;

		@include desktop {
			height: $header-simple-height;
			padding-bottom: 0;
		}
	}
}
