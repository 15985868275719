@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px;
	border: 1px solid $black10;
	position: relative;
	line-height: 20px;
	color: $black65;
	width: 250px;
	height: 242px;
	transition: border .3s ease;

	@include small-desktop {
		width: 300px;
	}

	&.company {
		height: 255px;
	}

	&.mainData {
		border-color: $black20;
		border-width: 1.5px;
	}

	&.active {
		border-color: $navy;
		padding-bottom: 36px;

		.check {
			display: flex;
		}
	}

	.addressSection {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.check {
		display: none;
		position: absolute;
		background-color: $navy;
		justify-content: center;
		align-items: center;
		height: 32px;
		width: 32px;
		top: 0;
		right: 0;
	}

	.selectButton {
		margin-top: 32px;
	}

	&.isMozaic {
		border-radius: 4px;
		border-color: $color-grey-100;
		color: $color-grey-800;

		&.active {
			border-color: $color-primary-01-600;

			.check {
				background-color: $color-primary-01-600;
				border-radius: 4px;
			}
		}
	}
}
