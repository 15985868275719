@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 40px;

	@include tablet {
		margin-top: 50px;
	}
}
