@import "~/styles/mixins.scss";
.wrapper {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;

	.world {
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		color: $navy;
		display: inline;
		text-align: left;

		&:hover {
			text-decoration: underline;
		}

		.linkWrapper {
			display: inline;
			margin-right: -10px;

			.arrow {
				margin-left: 4px;
			}
		}
	}

	.subWorlds {
		margin-top: 10px;
		font-size: 14px;
		color: $navy;
		display: flex;
		flex-direction: column;
	}
}
