@import "~/styles/mixins.scss";
.wrapper {
	.date {
		margin-top: 14px;
		font-weight: 700;

		@include tablet {
			margin-top: 24px;
		}
	}

	.calendar {
		width: 277px;
		height: 328px;
		border: 1px solid $black10;
		margin-top: 22px;

		@include tablet {
			margin-top: 14px;
		}

		@include small-desktop {
			width: 367px;
			height: 360px;
		}

		:global {
			.react-calendar__tile {
				&.available {
					color: $navy;
					font-weight: 700;
				}

				&:disabled {
					background: $white;

					&:hover {
						background: $white;
						cursor: not-allowed;
					}
				}

				&:enabled:hover,
				&:enabled:focus {
					background: $green;
					color: $white;
					font-weight: 600;
				}
			}

			.react-calendar__tile--active {
				background: $green;
				font-weight: 600;

				&:enabled:hover,
				&:enabled:focus {
					background: $green-dark;
					color: $white;
					font-weight: 600;
				}
			}

			.react-calendar__tile--hasActive {
				background: $green;

				&:hover {
					background: $green-dark;
				}
			}

			// month name
			.react-calendar__navigation__label__labelText {
				font-weight: 600;
				font-size: 16px;
				color: $black65;
				font-family: LMSans, sans-serif;
				text-transform: capitalize;
			}

			// week day name
			.react-calendar__month-view__weekdays__weekday {
				text-transform: capitalize;

				abbr[title] {
					text-decoration: none;
				}
			}

			// hover prev/next/month
			.react-calendar__navigation {
				height: 61px;
				margin: 0;

				button {
					&:enabled {
						&:hover,
						&:focus {
							background: none;
						}
					}

					&:disabled {
						background: $white;
						cursor: auto;

						svg {
							fill: none;
						}
					}
				}
			}

			// now
			.react-calendar__tile--now {
				background: $white;

				&:hover {
					background: $green-dark;
				}

				&.react-calendar__tile--active {
					background: $green;
					font-weight: 600;

					&:enabled:hover,
					&:enabled:focus {
						background: $green-dark;
						color: $white;
						font-weight: 600;
					}
				}
			}

			// neighbouring months days
			.react-calendar__month-view__days__day--neighboringMonth {
				color: $black10;
			}

			.react-calendar__month-view__days {
				height: 239px;

				@include small-desktop {
					height: 271px;
				}
			}
		}
	}
}
