@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: baseline;
	width: 100%;

	@include tablet {
		align-items: center;
		width: unset;
	}
}
