@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	line-height: 15px;
	font-size: 12px;
	margin-top: 5px;

	@include desktop {
		margin-top: 10px;
	}

	&:first-child {
		margin: 0;
	}

	&.redLabel {
		color: $red;
	}

	&.orangeLabel {
		color: $orange;
	}
}
