@import "~/styles/mixins.scss";
.wrapper {
	display: flex;

	&:hover {
		background-color: $black3;
	}

	.button {
		display: flex;
		justify-content: flex-start;
		height: 46px;
		padding-left: 40px;
		color: $navy;
		width: 100%;

		@include desktop {
			padding-left: 24px;
		}

		.icon {
			margin-right: 16px;
		}
	}
}
