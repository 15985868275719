@import "~/styles/mixins.scss";
.wrapper {
	padding: 45px 45px 25px;
	display: none;
	flex-wrap: wrap;
	flex: 1;

	&.active {
		display: flex;
	}

	.masonryGrid {
		display: flex;
		margin-left: -20px;
		width: auto;

		@include large-desktop {
			margin-left: -30px; /* gutter size offset */
		}

		.masonryColumn {
			padding-left: 20px; /* gutter size */
			background-clip: padding-box;

			@include large-desktop {
				padding-left: 30px; /* gutter size */
			}
		}
	}
}
