@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 6px;

	.text {
		color: $navy;
		text-decoration: underline;

		&:hover {
			color: $green;
		}
	}
}
