@import "~/styles/mixins.scss";
.wrapper {
	align-items: center;
	display: none;
	flex: 1;
	padding: 0 16px;
	position: relative;
	margin: auto;
	max-width: 746px;

	@include desktop {
		display: flex;
		justify-content: center;
		padding: 0;
	}

	&.modalView {
		display: flex;
		flex-direction: column;
		padding: 0;

		@include tablet {
			padding: 0 16px;
		}

		.inputWrapper {
			width: 100%;

			.searchButton {
				height: 100%;
				top: 0;
			}
		}

		.resultsWrapper {
			width: 100%;
		}
	}

	.inputWrapper {
		position: relative;
		height: 40px;
		flex: 1;
		flex-direction: row-reverse;

		@include desktop {
			height: 48px;
		}

		@include large-desktop {
			max-width: 712px;
		}

		.input {
			background: $white;
			border: 1px solid $black20;
			height: 100%;
			padding: 14px 15px 14px 47px;
			font-size: 16px;
			position: relative;
			width: 100%;

			&:focus-visible {
				@include focus-visible;

				z-index: 0;
			}

			&::placeholder {
				color: $black30;
				font-size: 16px;
			}

			&:focus {
				outline: none;
			}

			&.focused {
				box-shadow: unset;
			}
		}

		.searchButton {
			height: 100%;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 16px;

			&:hover {
				.icon {
					fill: $green-dark;
				}
			}

			.icon {
				transition: fill .2s ease;

				@include desktop {
					width: 22px;
					height: 24px;
				}
			}
		}

		.error {
			margin-top: 0;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			background-color: $white;
		}

		.clearButton {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
		}
	}

	.closeButton {
		color: $navy;
		text-decoration: underline;
		font-size: 12px;
		margin-bottom: 16px;

		@include desktop {
			display: none;
		}

		&:hover {
			color: $green-dark;
		}
	}
}
