@import "~/styles/mixins.scss";
.wrapper {
	align-items: center;
	justify-content: center;
	display: flex;
	padding-right: 13px;
	padding-top: 21px;
	margin-top: 10px;
	z-index: 1;
	position: relative;

	&:after {
		content: "";
		height: 5px;
		bottom: -5px;
		left: 0;
		background: $white;
		width: 100%;
		position: absolute;
	}

	&.scrollable {
		border-top: 1px solid $navy;
		box-shadow: 0 -1px 4px 0 $black40;
	}

	.shareButton {
		margin-right: 18px;
		display: flex;
		align-items: center;
		color: $navy;
		font-size: 12px;
		transition: color .2s ease;

		&:hover {
			color: $green-dark;
		}

		.icon {
			margin-right: 7px;
			transition: fill .2s ease;
		}
	}

	.button {
		padding: 0 18px;
	}
}
