@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;

	@include tablet {
		margin-top: 28px;
	}

	.caption {
		line-height: 17px;
	}

	.order {
		margin-top: 28px;

		@include tablet {
			margin-top: 32px;
		}
	}

	.dateTiles {
		margin-left: -16px;

		.dateTile {
			margin-top: 16px;
			margin-left: 16px;
		}
	}

	.label {
		width: auto;
	}
}
