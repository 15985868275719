@import "~/styles/mixins.scss";
.wrapper {
	display: flex;

	& + .wrapper {
		margin-top: 24px;

		@include tablet {
			margin-top: 16px;
		}
	}

	.content {
		margin-left: 15px;

		.price {
			margin-top: 5px;
			color: $black65;
			font-weight: 700;
		}
	}
}
