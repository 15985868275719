@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;

	@include tablet {
		width: 356px;
	}

	.loader {
		margin: 50px auto 0;
	}

	.caption {
		font-size: 18px;
		font-weight: 600;
		color: $black65;
	}

	.info {
		margin-top: 7px;
		font-size: 14px;
		color: $black65;
	}

	.button {
		width: 100%;
		margin-top: 30px;
	}
}
