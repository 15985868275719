@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;

	@include tablet {
		margin-top: 24px;
	}

	.button {
		margin-top: 6px;
		display: block;

		.text {
			color: $navy;
			text-decoration: underline;

			&:hover {
				color: $green;
			}
		}
	}
}
