@import "~/styles/mixins.scss";
.wrapper {
	border-bottom: 1px solid $black10;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 24px;

	.data {
		display: flex;
		flex-direction: column;

		@include desktop {
			flex-direction: row;
		}
	}

	&.isMozaic {
		border-bottom-color: $color-grey-100;
	}
}
