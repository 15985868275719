@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;

	.content {
		display: flex;
		align-items: center;

		.input {
			margin-top: 0;
			flex: 1;
			max-width: calc(100% - 16px);
		}
	}

	.button {
		width: 96px;
		top: 22px;
		position: absolute;
		right: 16px;

		&.isMozaic {
			width: 130px;
			top: 26px;

			@include tablet {
				width: 180px;
			}
		}

		@include tablet {
			width: 180px;
		}
	}

	.checkbox {
		.label {
			margin-left: 8px;
			font-size: 11px;
		}

		.collapseButton {
			display: inline;
		}
	}

	.recaptcha {
		margin-top: 16px;
		margin-left: 32px;
		max-width: 480px;

		@include desktop {
			margin-left: 24px;
		}
	}

	.link {
		color: $navy;
	}
}
