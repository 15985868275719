@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 2px;
	font-size: 11px;
	line-height: 14px;
	color: $red;
	white-space: pre-line;

	&.isMozaic {
		font-size: 14px;
		line-height: 18px;
		color: $color-danger-600;
	}
}
