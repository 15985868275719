@import "~/styles/mixins.scss";
.select {
	:global {
		.react-select {
			&__control {
				background-color: $black5;
			}

			&__option {
				width: 100%;
				display: flex;

				&:active {
					background-color: $black10;
				}

				&--is-selected {
					background-color: $white;
					color: $navy;
					font-size: 14px;
					line-height: 18px;
				}
			}

			&__value-container {
				.react-select__single-value {
					color: $navy;
					font-size: 14px;
					line-height: 18px;
					font-weight: 700;

					svg {
						display: none;
					}
				}
			}
		}
	}

	.option {
		display: flex;
		gap: 16px;
		align-items: center;
	}
}
