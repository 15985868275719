@import "~/styles/mixins.scss";
.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 12;
	width: 312px;
	height: 100vh;
	overflow-y: auto;
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
	visibility: hidden;

	&.open {
		transform: translateX(0);
		visibility: visible;
	}

	.content {
		border-top: 2px solid $green;
		border-left: 2px solid $green;
		border-bottom: 2px solid $green;
		background: $white;
		position: absolute;
		top: 50px;
		right: 0;
		width: 100%;
	}
}

.overlay {
	visibility: hidden;
	opacity: 0;
	background: $black55;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	transition: all .5s ease-in-out;
	z-index: 11;
	touch-action: none;

	&.visible {
		visibility: visible;
		opacity: 1;
	}
}
