@import "~/styles/mixins.scss";
.wrapper {
	border: 1px solid $navy;
	padding: 24px;

	@include tablet {
		padding: 24px 84px;
	}

	&.isMozaic {
		border-color: $color-primary-01-600;
	}

	.topBar {
		display: flex;
		align-items: flex-start;

		@include desktop {
			align-items: center;
		}
	}
}
