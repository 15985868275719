@import "~/styles/mixins.scss";
.wrapper {
	size: 12px;
	font-weight: 700;
	color: $black65;

	&.success {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		size: 12px;
		font-weight: 400;

		.iconWrapper {
			width: 16px;
			height: 16px;
		}

		.caption {
			font-weight: 400;
		}
	}
}
