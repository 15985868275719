@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	text-decoration: underline;
	line-height: 17px;
	transition: all .3s ease;
	margin-top: 18px;
	color: $navy;

	&:hover {
		color: $green;
		text-decoration: underline;
	}

	&.isMozaic {
		color: $color-secondary-green-500;
	}

	.icon {
		margin-right: 8px;
	}
}
