@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;
	position: relative;

	.inputWrapper {
		.maskInput {
			letter-spacing: 2px;
		}
	}

	.rightBottomAccessory {
		position: absolute;
		right: 0;
	}
}
