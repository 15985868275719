@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	font-size: 12px;
	line-height: 20px;

	&.isMozaic {
		.value {
			color: $color-primary-01-600;
		}
	}

	.label {
		color: $black65;
	}

	.value {
		margin-left: 2px;
		font-weight: 700;
		color: $green;
	}

	.info {
		margin-top: 12px;
		display: flex;

		.icon {
			margin-top: 2px;
		}

		.message {
			margin-left: 12px;
		}
	}
}
