@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
	margin-right: 0;
	min-width: 0;
	min-height: 0;

	@include tablet {
		max-width: 975px;
		width: 100%;
		flex: 1;
		margin-right: 32px;
	}
}
