@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;

	.button {
		width: 100%;
		margin: 5px;
	}
}
