@import "~/styles/mixins.scss";
.label {
	font-weight: 600;
	color: $white;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	white-space: pre;

	&.normal {
		padding: 3px 8px;
		font-size: 11px;
		line-height: 14px;
		position: inherit;
	}

	&.big {
		padding: 4px 12px;
		font-size: 14px;
		line-height: 17px;
	}

	/* stylelint-disable selector-class-pattern */
	&.promotion,
	&.sale,
	&.outlet,
	&.local_sale,
	&.super_offer {
		background-color: $red;
	}
	/* stylelint-enable selector-class-pattern */

	&.new,
	&.novelty {
		background-color: $blue;
	}
}
