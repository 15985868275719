@import "~/styles/mixins.scss";
.wrapper {
	.header {
		margin-top: 0;
	}

	.info {
		margin: 20px 0 40px;
		font-size: 16px;
	}

	.link {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s ease;
		white-space: nowrap;
		height: 48px;
		padding: 0 16px;
		background: $navy;
		border: 2px solid $navy;
		color: $white;

		@include mobile {
			padding: 0 58px;
		}

		&:focus-visible {
			@include focus-visible;
		}

		&:hover {
			background: $navy-dark;
			border: 2px solid $navy-dark;
		}
	}
}
