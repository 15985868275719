@import "~/styles/mixins.scss";
.wrapper {
	.header {
		margin: 0;
	}

	.content {
		max-width: 100%;

		@include tablet {
			max-width: 395px;
		}

		.description {
			margin-top: 32px;
			line-height: 20px;

			@include tablet {
				margin-top: 24px;
				color: $black55;
			}
		}
	}
}
