@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: 100%;
	background: $white;
	position: absolute;
	top: 47px;
	max-height: 0;
	overflow: hidden;
	visibility: hidden;
	transition: max-height .2s ease, border .2s ease, visibility .2s ease;
	will-change: max-height;

	&.visible {
		visibility: visible;
		max-height: 1000px;
	}
}
