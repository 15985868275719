@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	font-size: 12px;
	line-height: 14px;

	.currentTime {
		margin-left: 3px;
	}
}
