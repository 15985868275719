@import "~/styles/mixins.scss";
.wrapper {
	height: $header-simple-height;
	background: $gray95;
	border-bottom: 16px solid $white;
	z-index: 2;
	position: relative;

	@include desktop {
		border-bottom: none;
	}

	.content {
		padding: 0 0 16px 138px;
		flex-direction: row;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 80px;

		@include desktop {
			padding-bottom: 0;
		}

		@include large-desktop {
			padding: 0 0 16px 120px;
		}
	}
}
