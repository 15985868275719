@import "~/styles/mixins.scss";
.wrapper {
	overflow-y: auto;

	.content {
		@include tablet {
			width: 365px;
		}
	}

	.form {
		margin-top: 40px;
	}
}
