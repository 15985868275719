@import "~/styles/mixins.scss";
.wrapper {
	.header {
		margin-top: 32px;
		font-size: 14px;
		line-height: 18px;
	}

	.map {
		height: 410px;
		margin-top: 8px;
	}
}
