@import "~/styles/mixins.scss";
.wrapper {
	.header {
		display: flex;
		flex-direction: column;
		line-height: 20px;
		color: $black65;
		margin-bottom: 24px;
	}
}
