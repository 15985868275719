@import "~/styles/mixins.scss";
.wrapper {
	position: absolute;
	width: 100%;
	height: 48px;
	top: 0;
	left: 0;
	display: flex;

	.menuItems {
		justify-content: space-between;
		display: flex;
		flex: 1;
		color: $navy;
	}

	.firstSection,
	.secondSection {
		display: flex;
		align-items: center;
	}

	.firstSection {
		padding-left: 20px;
	}

	.secondSection {
		padding-right: 20px;
	}
}
