@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 45px;

	.header {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 16px;

		@include small-desktop {
			margin-bottom: 30px;
		}
	}

	.apps {
		display: flex;

		@include small-desktop {
			flex-direction: column;
		}

		.link {
			display: block;
			height: 40px;
			width: 134px;
			position: relative;
			margin: 0 16px 10px 0;

			@include small-desktop {
				margin: 0 0 10px;
			}

			.image {
				object-fit: contain;
				object-position: 0;
			}
		}
	}
}
