@import "~/styles/mixins.scss";
.wrapper {
	margin-right: 20px;
	z-index: 2;
	height: 100%;
	padding: 0 20px;
	position: relative;

	&.active {
		background: $navy;

		.icon {
			fill: $white;
		}

		&:hover {
			.icon {
				fill: $white;
			}
		}
	}

	&:hover {
		.icon {
			fill: $green-dark;
		}
	}

	.iconWrapper {
		display: block;
		position: relative;
		width: 25px;
		height: 25px;
	}

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		transition: fill .2s ease;
	}
}
