@import "~/styles/mixins.scss";
.wrapper {
	color: $navy;
	line-height: 17px;

	&.isMozaic {
		color: $black;
		text-decoration: underline;
		font-size: 14px;

		&:hover {
			color: $color-grey-600;
		}
	}
}
