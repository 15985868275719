@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 300px;
	margin-top: 20px;

	@include tablet {
		margin-top: 44px;
	}

	.loaderWrapper {
		flex: 1;
		align-items: center;
		justify-content: center;
		display: flex;
	}
}
