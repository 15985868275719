@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 12px;
	font-size: 12px;
	color: $navy;

	&:hover {
		color: $green;
	}

	.query {
		font-weight: 600;
		margin-left: 3px;
	}

	.icon {
		margin-left: 6px;
	}
}
