@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;

	@include small-desktop {
		width: 770px;
	}

	.sections {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include small-desktop {
			flex-direction: row;
			width: auto;
		}
	}

	.preferredStoreWrapper {
		margin-top: 20px;
		display: none;

		@include desktop {
			display: flex;
		}
	}
}
