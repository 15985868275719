@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 200px;

	@include desktop {
		width: 200px;
	}

	.title {
		color: $navy;
		margin-bottom: 10px;
		line-height: 17px;

		&:hover {
			text-decoration: underline;
		}

		&.isMozaic {
			color: $black;
			font-size: 14px;
			margin-bottom: 5px;
		}
	}

	.reference {
		font-size: 12px;
		color: $black55;

		&.isMozaic {
			color: $color-grey-600;
		}
	}

	.price {
		display: block;
		font-weight: 700;
		margin-top: 8px;

		@include desktop {
			display: none;
		}

		&.promotion {
			color: $red;
		}

		.unit {
			font-weight: 400;
		}

		.labels {
			margin-top: 8px;
			font-weight: 400;
		}
	}

	.delivery {
		display: none;

		@include desktop {
			display: block;
			margin-top: 8px;
		}
	}
}
