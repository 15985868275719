@import "~/styles/mixins.scss";
.wrapper {
	display: none;
	width: 100%;

	@include desktop {
		display: flex;
	}
}

.modal {
	min-width: 95vw;
	padding: 16px 12px;

	@include tablet {
		min-height: 75vh;
		padding: 16px 32px 32px;
	}

	@include desktop {
		display: none;
	}

	.button {
		display: flex;
		width: 110%;
		align-items: center;
		justify-content: flex-start;
		border-bottom: solid 1px $black10;
		padding: 0 0 16px 16px;
		margin: 0 -16px 24px;

		@include tablet {
			margin: 0 -32px 24px;
		}
	}

	.arrow {
		transform: rotate(180deg);
		margin-right: 16px;
	}

	.label {
		font-size: 14px;
	}
}
