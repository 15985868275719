@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;

	.data {
		margin-left: 24px;

		@include small-desktop {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 660px;
		}

		.description {
			display: flex;

			.caption {
				line-height: 14px;
				margin-right: 8px;
				color: $black65;
			}
		}

		.price {
			margin-left: 3px;
			font-weight: 700;
		}
	}

	.realization,
	.payment {
		margin-top: 8px;

		@include small-desktop {
			margin-top: 0;
		}
	}
}
