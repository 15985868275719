@import "~/styles/mixins.scss";
.wrapper {
	.caption {
		font-size: 18px;
		font-weight: 700;
	}

	.description {
		margin-top: 16px;
		line-height: 20px;
	}

	.button {
		width: 100%;
		margin-top: 32px;
	}

	.icon {
		display: none;
	}
}
