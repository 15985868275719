@import "~/styles/mixins.scss";
.wrapper {
	.inputWrapper {
		margin-top: 0;

		.input {
			position: relative;
			pointer-events: inherit;

			&.selected {
				pointer-events: none;
			}

			.inputElement {
				font-size: 12px !important;
				color: $navy !important;
				height: 46px;
			}

			.label {
				font-size: 12px !important;
				font-weight: 700 !important;
				color: $black65 !important;
				font-family: LMSans, sans-serif;

				&.selected {
					display: none;
				}

				&.editMode {
					display: unset;
				}
			}
		}
	}

	.icon {
		top: 35px;
		position: absolute;
		padding: 8px 12px 8px 8px;
		background-color: $white;
		margin-right: 1px;

		&.disabled {
			display: none;
		}
	}

	.mozaicIcon {
		display: none;

		&.disabled {
			display: block;
		}
	}
}
