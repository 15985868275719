@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	max-width: 100%;

	&.withMap {
		width: 100%;
	}

	.radio {
		display: flex;

		.content {
			margin-left: 8px;
			line-height: 20px;

			.charge {
				font-weight: 700;

				.price {
					margin-left: 3px;
				}
			}
		}
	}
}
