@import "~/styles/mixins.scss";
.wrapper {
	$padding-value: min(5vw, 70px);

	padding-top: 32px;
	border-top: 1px solid $black10;
	flex-basis: auto;

	@include tablet {
		flex-basis: calc(425px + $padding-value);
		padding-top: 64px;
		border-top: 0 none;
		position: relative;
		padding-left: $padding-value;
	}

	.caption {
		font-size: 24px;
		font-weight: 600;
		color: $black65;
	}

	.description {
		margin-top: 6px;
		font-size: 14px;
		line-height: normal;
		color: $black65;
	}

	.button {
		width: 100%;
		max-width: unset;
		margin-top: 40px;

		@include tablet {
			max-width: 400px;
		}
	}
}
