@import "~/styles/mixins.scss";
.wrapper {
	border: 1px solid $black10;
	position: relative;

	&:hover,
	&.selected {
		border-color: $navy;
	}

	&.selected,
	&.disabled {
		pointer-events: none;
	}

	&.disabled {
		border-color: $black10;

		* {
			color: $black15 !important;
			fill: $black15 !important;
		}
	}

	.selected {
		background: $navy;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: -1px;
		top: -1px;
	}

	&.isMozaic {
		border-radius: 4px;
		border-color: $color-grey-100;

		&:hover,
		&.selected {
			border-color: $color-primary-01-600;
		}

		&.disabled {
			border-color: $color-grey-300;
		}

		.selected {
			background: $color-primary-01-600;
			border-radius: 4px;
		}
	}
}
