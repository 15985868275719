@import "~/styles/mixins.scss";
.wrapper {
	.header {
		font-size: 18px;
		font-weight: 600;
		color: $black65;
	}

	.data {
		margin-top: 4px;
		font-size: 14px;
		line-height: 19px;
		color: $black65;

		.link {
			margin-top: 9px;
		}
	}

	.info {
		margin-top: 13px;
		line-height: 20px;

		.link {
			color: $navy;
			margin-left: 8px;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
