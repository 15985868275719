@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 24px 0 24px 20px;
	width: 12%;
	flex-shrink: 0;

	.price {
		font-weight: 600;
		white-space: nowrap;

		&.isMozaic {
			color: $black;
			font-size: 14px;
		}

		&.promotion {
			color: $red;
		}
	}
}
