@import "~/styles/mixins.scss";
.wrapper {
	&.isMozaic {
		.cityControls,
		.addressNumbersControls {
			align-items: flex-end;

			.control {
				@include tablet {
					&:first-child {
						max-width: none;
					}
				}
			}
		}

		.nipControlIcon {
			display: none;
		}
	}

	.addressNumbersControls {
		display: flex;

		.control {
			width: calc(50% - 8px);
		}

		.control:last-child {
			margin-left: 16px;
		}
	}

	.cityControls {
		@include tablet {
			display: flex;
		}

		.control {
			@include tablet {
				&:first-child {
					max-width: 125px;
				}

				&:last-child {
					margin-left: 16px;
				}
			}
		}
	}

	.nipButton {
		width: 96px;
		height: 26px;
		font-weight: 400;
	}
}
