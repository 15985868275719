@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	align-self: center;
	margin: 13px auto 0;
	color: $navy;

	.icon {
		margin-right: 9px;
	}
}
