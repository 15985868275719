@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 30px;

	.icon {
		margin-right: 13px;

		@include mobile {
			margin-right: 23px;
		}
	}

	.title {
		font-weight: 700;
		font-size: 18px;
		color: $black65;

		@include mobile {
			font-size: 24px;
		}
	}
}
