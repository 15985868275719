@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 100%;

	@include tablet {
		width: min(20px, 2vw);
		margin: 0 15px;
	}

	@include small-desktop {
		width: min(60px, 3vw);
		margin: 0 39px;
	}

	.inner {
		width: 100%;
		border-bottom: 1px solid $black10;

		&.active {
			border-bottom: 1px solid $green;
		}

		&.isMozaic {
			border-color: $color-grey-300;

			&.active {
				border-color: $color-secondary-green-500;
			}
		}
	}
}
