@import "~/styles/mixins.scss";
.wrapper {
	.link,
	.customLink {
		line-height: 32px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		padding-left: 10px;

		@include small-desktop {
			padding-left: 0;
		}

		&:hover {
			color: $white;
			text-decoration: underline;
		}

		&:focus-visible {
			@include focus-visible;
		}
	}
}
