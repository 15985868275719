@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	margin-top: 15px;
	padding-top: 15px;
	border-top: 1px solid $black10;

	.aside {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 15px;

		.details {
			display: flex;
			flex-direction: column;
			line-height: 20px;

			.quantity {
				color: $black30;
			}

			.price {
				font-weight: 700;
			}
		}
	}
}
