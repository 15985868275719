@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	background-color: $black5;
	padding: 0 16px;

	@include small-desktop {
		width: 272px;
		flex-shrink: 0;
		padding: 0 30px;
	}

	.caption {
		display: none;

		@include small-desktop {
			display: block;
			font-size: 18px;
			font-weight: 700;
			color: $black65;
			padding-top: 30px;
		}
	}
}
