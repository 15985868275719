@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	.button {
		height: 48px !important;

		@include tablet {
			height: 40px !important;
		}
	}
}
