@import "~/styles/mixins.scss";
.carouselNavigation {
	width: 64px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: linear-gradient(270deg, $white20 20%, $white05 100%);
	z-index: 1;
	pointer-events: none;
	opacity: 1;
	transition: opacity .2s ease;

	@include small-desktop {
		width: 128px;
	}

	&.prev {
		background: linear-gradient(-270deg, $white20 20%, $white05 100%);
		right: auto;
		left: 0;
	}

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

	&.withoutGradient {
		background: none;
	}

	:global(.swiper-button-prev),
	:global(.swiper-button-next) {
		width: 48px;
		height: 48px;
		border: 1px solid $black10;
		border-radius: 30px;
		background-color: $white;
		z-index: 2;
		pointer-events: all;

		&:after {
			content: "";
		}

		&:hover {
			.icon {
				fill: $green;
			}
		}
	}

	:global(.swiper-button-prev) {
		.icon {
			transform: rotate(180deg);
		}
	}

	.icon {
		transition: fill .2s ease;
	}
}
