@import "~/styles/mixins.scss";
.wrapper {
	position: absolute;
	width: 256px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	z-index: 1;
	top: 0;
	left: -84px;
	height: 64px;
	overflow: hidden;

	@include tablet {
		margin-left: min(80px, 1vw);
		left: -86px;
	}

	@include desktop {
		height: auto;
		transition: height .3s ease-in-out;
		align-items: flex-start;
		will-change: height;
		left: -72px;
	}

	&.withFixedHeader {
		@include desktop {
			.logo {
				&.withoutAnimation {
					position: relative;
					top: 21px;
				}

				.link {
					margin-top: 25px;

					&.fixedSize {
						width: 70px;
						height: 42px;
					}

					&.withoutAnimation {
						width: 79px;
						height: 48px;
						margin-top: 0;
					}
				}
			}
		}
	}

	.triangle {
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 70px 70px;
		border-color: transparent transparent $white transparent;
		transition: border-width 0.2s ease;

		@include desktop {
			border-width: 0 127px 127px;

			&.fixedTriangle {
				border-width: 0 80px 80px;
			}
		}
	}

	.logo {
		position: relative;
		margin-bottom: 8px;
		top: 0;

		@include desktop {
			margin-bottom: 32px;
			position: static;

			&.withoutAnimation {
				position: relative;
				top: 33px;
				transition: top .3s ease-in-out;
				will-change: top;
			}
		}

		.link {
			position: relative;
			display: block;
			width: 66px;
			height: 40px;

			&:hover {
				.img {
					transform: none;
				}
			}

			@include desktop {
				will-change: width, height;
				margin-top: 46px;
				transition: all .3s ease;

				&:hover {
					.img {
						transform: scale(0.95);
					}
				}
			}

			&.desktopSize {
				@include desktop {
					width: 102px;
					height: 62px;
				}
			}

			&.withoutAnimation {
				width: 79px;
				height: 48px;
				margin-top: 0;
			}

			.img {
				transition: transform .2s ease;
				width: 100%;
				height: auto;
			}
		}
	}
}
