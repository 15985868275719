@import "~/styles/mixins.scss";
.wrapper {
	padding: 10px;

	.name {
		font-size: 18px;
		font-weight: 600;
	}

	.address,
	.openingHours {
		margin-top: 10px;
		font-size: 14px;
		line-height: 18px;
	}

	.openingHours {
		.caption {
			font-size: 16px;
			font-weight: 600;
		}

		.list {
			margin-top: 10px;

			.dayOfWeek {
				display: flex;

				.weekDay {
					width: 100px;
				}
			}
		}
	}

	.button {
		margin-top: 10px;
	}
}
