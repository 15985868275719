@import "~/styles/mixins.scss";
.modal {
	padding: 53px 15px 23px !important;
	width: 100% !important;
	overflow-y: auto;

	@include mobile {
		padding: 33px 73px 23px !important;
		width: 842px !important;
	}
}
