@import "~/styles/mixins.scss";
.searchButton {
	margin-right: 16px;
	z-index: 2;

	@include desktop {
		display: none;
	}

	&:hover {
		.icon {
			fill: $green-dark;
		}
	}

	.icon {
		transition: fill .2s ease;
	}
}
