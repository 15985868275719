@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	height: 48px;
	width: 100%;
	position: relative;
	align-items: center;

	&:hover {
		.title {
			text-decoration: underline;
		}
	}

	&:first-of-type {
		margin-top: 14px;
	}

	&.active {
		&:before {
			content: "";
			height: 48px;
			width: 5px;
			position: absolute;
			left: -16px;
			background-color: $green;

			@include small-desktop {
				left: -30px;
			}
		}

		.title {
			font-weight: 700;
		}
	}

	.title {
		color: $navy;
	}

	.iconWrapper {
		width: 31px;
	}
}
