@import "~/styles/mixins.scss";
.button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;

	&.isMozaic {
		border-left: 1px solid;
		display: flex;
		height: 100%;
		right: 0 !important;
		width: 57px;
		color: $color-grey-600;
	}
}
