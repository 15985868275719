@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	padding: 16px 0;

	@include tablet {
		padding: 16px 24px;
	}

	.radio {
		margin-top: 0;
		flex-direction: column;

		.label {
			& * {
				font-size: 12px !important;
			}

			& + .label {
				margin-top: 16px;
			}

			&.isMozaic {
				color: $color-grey-600 !important;
			}
		}
	}

	.input {
		width: 100%;
		margin-bottom: 10px;
	}
}
