@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;

	:global {
		.swiper {
			padding-bottom: 8px;
		}
	}

	.product {
		border: none !important;
	}

	.checkAvailabilityButton {
		padding-right: 8px !important;
		padding-left: 8px !important;
		font-size: 12px !important;
		line-height: 15px !important;
		white-space: pre-line !important;
	}
}
