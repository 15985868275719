@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 100%;
	padding: 73px 16px 24px;
	background-color: $white;
	position: relative;

	@include tablet {
		width: auto;
		height: auto;
		max-height: 90vh;
		padding: 88px 98px;
	}

	&.withTitle {
		padding-top: 72px;

		@include tablet {
			padding-top: 85px;
		}
	}

	&:focus-visible {
		outline: none;
	}

	.closeButton {
		width: 100%;
		height: 48px;
		padding-right: 25px;
		border-bottom: 1px solid $black10;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		top: 0;
		left: 0;

		@include tablet {
			top: 5px;
			border-bottom-color: transparent;
		}
	}

	.title {
		font-weight: 600;
		font-size: 18px;
		color: $black65;
		margin-bottom: 24px;
		line-height: normal;
	}
}

.html {
	overflow: hidden;
}

.overlay {
	background-color: $black55;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	opacity: 1;

	@include tablet {
		opacity: 0;
		transition: opacity .5s ease;
	}

	&.afterOpen {
		opacity: 1;
	}

	&.beforeClose {
		opacity: 0;
	}
}
