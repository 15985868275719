@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;

	&.wrapperError {
		margin-top: 0;
	}

	.pointsContainer {
		display: flex;
		flex-direction: column;

		.pointsCaption {
			font-size: 12px;
			line-height: 14px;
			color: $black65;
		}

		.points {
			color: $green;
			font-size: 14px;
			line-height: 16px;
			font-weight: 700;
		}
	}

	.pointsLink {
		font-size: 12px;
		line-height: 17px;
		text-decoration: underline;
		color: $navy;
		transition: all 0.3s ease-in-out;

		&:hover {
			text-decoration: none;
			color: $green;
		}

		&.pointsLinkError {
			width: 100%;
			text-align: center;
			margin-bottom: 24px;
		}
	}
}
