@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	width: auto;
	margin: 48px 0 0;

	@include small-desktop {
		margin: 0;
		width: 340px;
		flex-flow: row wrap;
	}

	@include desktop {
		width: 140px;
	}

	@include large-desktop {
		margin-left: auto;
		width: 340px;
	}

	.banner {
		display: block;
		margin-bottom: 30px;

		@include small-desktop {
			width: 140px;
			display: flex;
			flex-direction: column;

			&:nth-child(even) {
				margin-left: 60px;
			}
		}

		@include desktop {
			&:nth-child(even) {
				margin-left: 0;
			}
		}

		@include large-desktop {
			&:nth-child(even) {
				margin-left: 60px;
			}
		}

		.imgWrapper {
			display: inline-block;
			vertical-align: middle;
			height: 90px;
			width: 139px;
			position: relative;

			@include small-desktop {
				display: block;
			}

			.image {
				object-fit: contain;
				object-position: 0;
			}
		}

		.label {
			line-height: 20px;
			vertical-align: middle;
			display: inline-block;
			width: 150px;
			margin-left: 16px;

			@include small-desktop {
				width: auto;
				margin: 10px 0 0;
			}
		}
	}
}
