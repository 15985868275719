@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	border: 1px solid $black10;
	margin-top: 38px;

	&.isMozaic {
		.button {
			color: $black;
		}
	}

	.legend {
		padding: 16px 0;
		font-size: 12px;
		line-height: 14px;
		color: $black55;

		@include tablet {
			padding: 16px 24px;
		}
	}

	.button {
		display: flex;
		justify-content: space-between;
		width: 100%;
		color: $navy;
		font-weight: 700;
		padding: 16px 24px;
		line-height: 16px;
		white-space: normal;
		text-align: left;
		pointer-events: initial;

		@include tablet {
			pointer-events: none;
		}

		&.lys {
			pointer-events: initial;
		}

		.arrow {
			display: flex;
			transform: rotate(90deg);

			@include tablet {
				display: none;
			}

			&.arrowActive {
				transform: rotate(-90deg);
			}

			&.arrowLys {
				display: flex;
			}
		}
	}
}
