@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: auto;
	line-height: 17px;
	margin: 3px 0;

	.linkWrapper {
		display: flex;
		width: auto;

		.link {
			text-align: left;
		}

		&:hover {
			color: $navy;
			text-decoration: underline;
		}
	}
}
