@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	width: 257px;
	color: $navy;

	.menu {
		width: 100%;
		margin-top: 0;

		.itemWrapper {
			.button {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 0 0 0 31px;
				width: 100%;
				height: 61px;

				&:hover {
					color: $green;
					background-color: $black5;
				}
			}
		}

		.logoutWrapper {
			border-top: 1px solid $black10;

			.logoutButton {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 31px;
				margin-bottom: -10px;
				width: 100%;
				height: 61px;

				&:hover {
					color: $green;
					background-color: $black5;
				}
			}
		}
	}
}
