@import "~/styles/mixins.scss";
@import "@mozaic-ds/react/lib/index.css";
@import "~normalize-scss/sass/normalize";
@import "./mixins";
@import "./react-select";

@font-face {
	font-family: LMSans;
	src: url("/static/fonts/LeroyMerlinSans-Regular.woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: LMSans;
	src: url("/static/fonts/LeroyMerlinSans-Bold.woff2");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Turbinado;
	src: url("/static/fonts/Turbinado.woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

html,
body {
	padding: 0;
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $black55;
	line-height: 1;
}

html {
	overflow: auto;
	overflow-x: hidden;
}

body {
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

body,
button,
input {
	/* stylelint-disable-next-line font-family-name-quotes */
	font-family: "LMSans", sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-radius: 0;

	&[type="search"] {
		appearance: none;

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}

	&[type="number"]::-webkit-outer-spin-button,
	&[type="number"]::-webkit-inner-spin-button {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-moz-appearance: textfield;
	}
}

* {
	box-sizing: border-box;
	font: inherit;
}

a,
button,
input[type="submit"] {
	padding: 0;
	border: 0 none;
	text-decoration: none;
	cursor: pointer;
	outline: none;
	font: inherit;
	appearance: none;
	color: inherit;
	background-color: transparent;
	transition: color .2s ease;

	&:hover {
		text-decoration: none;
	}
}

a:hover {
	color: $green-dark;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	user-select: none;
}

strong {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 600;
}

ol,
ul,
dl {
	list-style: none;
	margin: 0;
	padding: 0;
}

li,
p {
	font-size: 16px;
	line-height: 21px;
}

p,
h1 {
	margin: 0;
	padding: 0;
}

::selection {
	color: $white;
	background: $green;
}

.grecaptcha-badge {
	visibility: hidden;
}

div[role="button"] {
	&:focus-visible {
		@include focus-visible;
	}
}
