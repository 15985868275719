@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 14px;

	@include tablet {
		margin-top: 24px;
	}

	.map {
		height: 410px;
	}
}
