@import "~/styles/mixins.scss";
.breadcrumb {
	font-size: 12px;
	display: flex;
	align-items: center;

	.item {
		&:before {
			content: "/";
			margin-left: 6px;
			color: $navy;
		}
	}
}
