@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex: 1;
	margin-top: 16px;
	flex-direction: column;

	@include small-desktop {
		flex-direction: row;
		margin-top: 60px;
		align-items: flex-start;
	}

	@include desktop {
		margin-top: 0;
	}
}
