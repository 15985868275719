@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	cursor: auto;

	.form {
		.inputs {
			display: flex;

			.label {
				height: 25px;
			}

			.cityField {
				margin-left: 16px;
			}
		}

		.chosenCityField {
			:global(.react-select__indicator) {
				display: none;
			}
		}
	}
}
