@import "~/styles/mixins.scss";
.wrapper {
	display: block;
	flex: 1;
	max-width: 975px;
	margin-top: 37px;

	@include desktop {
		display: flex;
		flex-direction: column;
		border-top: none;
	}

	.caption {
		margin-top: 10px;
		font-weight: 700;
		color: $black65;
		line-height: 22px;
		font-size: 14px;
	}
}
