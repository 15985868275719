@import "~/styles/mixins.scss";
.wrapper {
	.signInWrapper {
		border-right: none;

		.signInActions {
			display: none;
		}
	}
}

.overlay {
	background-color: $white;
}
