@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;
	text-align: left;

	.formControl {
		display: flex;
		position: relative;
		cursor: pointer;

		.input {
			width: 0;
			height: 0;
			appearance: none;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;

			&:checked ~ .checkbox {
				border-color: $green;
				background-color: $green;

				&.isMozaic {
					border-radius: 4px;
					border-color: $color-secondary-green-500;
					background-color: $color-secondary-green-500;
				}

				.icon {
					display: block;
				}
			}
		}

		.checkbox {
			width: 24px;
			height: 24px;
			border: 1px solid $black10;
			background-color: $white;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			@include desktop {
				width: 16px;
				height: 16px;
			}

			&.isMozaic {
				border-radius: 4px;
				border: 2px solid $color-grey-600;

				@include desktop {
					width: 18px;
					height: 18px;
				}
			}

			&.error {
				border-color: $red;
			}

			&.focusVisibleIcon {
				@include focus-visible;

				z-index: 0;
			}

			.icon {
				display: none;
				width: 16px;

				@include desktop {
					width: 11px;
				}
			}
		}
	}

	.label {
		margin-left: 16px;
		font-size: 14px;
		line-height: normal;
		display: flex;

		&.required {
			&:before {
				margin-right: 5px;
				content: "* ";
			}
		}

		a {
			color: $green;
		}
	}

	&.focused {
		.checkbox {
			box-shadow: unset !important;
		}
	}
}
