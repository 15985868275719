@import "~/styles/mixins.scss";
.wrapper {
	border-top: 1px solid $black5;

	.deliveryLabel {
		border: 1px solid $black10;
		border-top-color: $black5;
		border-bottom-color: $black5;
		margin-top: -1px;
		width: 100%;
		padding: 24px 20px;

		@include tablet {
			padding: 24px 31px;
		}

		@include small-desktop {
			border-top: none;
		}

		&.selected {
			border: 1px solid $navy;
		}

		&.disabled {
			opacity: .35;
			border-top: 1px solid $black15;
		}
	}

	&.isMozaic {
		border-top-color: $color-grey-100;

		.deliveryLabel {
			border-color: $color-grey-100;

			&.selected {
				border-color: $color-primary-01-600;
			}
		}
	}
}
