@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 100%;
	background-color: $white;
	transition: border .2s ease;
	position: relative;
	z-index: 0;

	@include tablet {
		padding: 32px 24px;
		border: 2px solid $white;

		&:hover {
			border: 2px solid $navy;
		}
	}
}
