@import "~/styles/mixins.scss";
.wrapper {
	.address {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.control {
			width: calc(50% - 8px);
		}
	}

	.postal {
		@include tablet {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.zip {
				max-width: 125px;
				margin-right: 16px;
			}

			.city {
				width: 100%;
			}
		}
	}

	.nipButton {
		width: 74px;
		height: 32px;
		margin-left: 8px;
		margin-right: 16px;
		padding: 0;

		@include tablet {
			margin-right: 8px;
		}

		&.isMozaic {
			width: 100%;
			margin-right: 0;
		}
	}

	.nipLabel {
		display: flex;
	}

	.nipAdditionalLabel {
		margin-left: 8px;
		margin-bottom: 8px;
	}
}
