@import "~/styles/mixins.scss";
.wrapper {
	.radios {
		max-width: 400px;
		margin-top: 24px;

		.radioLabel {
			flex-basis: auto;

			& + .radioLabel {
				margin-top: 28px;
			}
		}
	}
}
