@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	padding: 0 16px 50px;
	flex-direction: column;
	align-items: flex-start;

	@include small-desktop {
		padding: 40px 16px 50px;
	}

	@include desktop {
		flex-flow: row wrap;
	}

	@include large-desktop {
		padding: 40px 0 50px;
	}
}
