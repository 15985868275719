@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 4px;
	line-height: normal;
	color: $black65;

	.list {
		list-style-position: inside;
		line-height: 18px;

		.listElement {
			display: flex;
			gap: 6px;
			align-items: center;
		}

		.valid {
			color: $green;
			font-size: 12px;
		}

		.invalid {
			color: $red;
			font-size: 12px;
		}

		.iconText {
			position: absolute;
			opacity: 0;
			width: 0;
			height: 0;
		}
	}
}
