@import "~/styles/mixins.scss";
.wrapper {
	.mainWrapper {
		display: flex;
		flex-direction: column;
		margin-top: 32px;

		@include small-desktop {
			align-items: flex-start;
			flex-direction: row;
			margin-top: 40px;
		}

		.content {
			position: relative;
			width: 100%;
			height: 100%;
			padding-top: 40px;
			display: block;

			@include small-desktop {
				padding: 32px 0 0 64px;
			}
		}
	}
}
