@import "~/styles/mixins.scss";
.wrapper {
	align-items: center;
	display: flex;
	border-bottom: 1px solid $white10;
	margin: 0 16px;
	padding: 8px 0 30px;
	flex-wrap: wrap;
	justify-content: space-between;

	@include tablet {
		padding: 24px 0;
		flex-wrap: nowrap;
		justify-content: unset;
	}

	@include large-desktop {
		padding: 40px 0;
		margin: 0 auto;
	}

	.button {
		border: 1px solid $white30;
		transition: all .2s ease;
		display: flex;
		align-items: center;
		width: calc(50% - 8px);
		height: 48px;
		margin-top: 16px;

		@include tablet {
			width: auto;
			margin-top: 0;

			& + .button {
				margin-left: 16px;
			}
		}

		&:hover {
			background: $white;
			color: $navy;
		}

		.linkWrapper {
			padding: 8px 12px;
			width: 100%;

			@include tablet {
				padding: 17px 20px;
			}

			.label {
				line-height: 16px;
				font-size: 12px;
				text-align: left;
				margin-right: 8px;

				@include tablet {
					font-size: 14px;
				}
			}

			.icon {
				width: 8px;
			}
		}
	}
}
