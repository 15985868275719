@import "~/styles/mixins.scss";
.wrapper {
	position: absolute;
	width: 100%;
	top: 40px;
	left: 0;
	padding: 16px 0;
	border: solid 1px $navy;
	background: $white;
	z-index: 3;

	@include desktop {
		width: 270px;
		margin: 0;
		top: 48px;
	}

	&.modal {
		max-height: 75vh;
		border: none;
		margin-top: 16px;

		.loader {
			margin: 0 auto;
		}
	}

	.loader {
		margin-left: 47px;
	}
}
