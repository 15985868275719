@import "~/styles/mixins.scss";
.wrapper {
	.headerCaption {
		font-size: 18px;
		line-height: 21px;
		color: $black65;
		font-weight: 700;
	}

	.content {
		&.legend {
			padding: 16px 0;
			font-size: 14px;
			line-height: 16px;
			color: $black55;
			display: flex;
			flex-direction: column;
			row-gap: 16px;
		}
	}
}
