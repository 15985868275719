@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	color: $black65;

	&.selected {
		color: $navy;
	}

	.couponCaption {
		text-align: justify;
		font-size: 11px;
		line-height: 13px;
	}

	.couponDescriptionContainer {
		font-size: 9px;
		line-height: 11px;
		text-align: left;

		.couponDescriptionDate {
			font-weight: 700;
		}
	}
}
