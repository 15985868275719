@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 14px;
	padding-top: 14px;
	border-top: 1px solid $black10;

	.caption {
		font-size: 14px;
		font-weight: 600;
		color: $black65;
	}

	.items {
		margin-top: 5px;

		.item {
			font-size: 12px;
			line-height: 19px;
			color: $black65;
			display: flex;
			justify-content: space-between;
		}
	}
}
