@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	height: 16px;

	&:hover {
		.icon {
			fill: $green-dark;
		}

		.label {
			color: $green-dark;
		}
	}

	&:before,
	.label {
		display: none;
	}

	&:before {
		content: "/";
		margin-left: 6px;
		color: $navy;
	}

	&.isMozaic {
		padding: 0 !important;

		.label {
			font-size: 14px;
			line-height: 18px;
			display: inline-block;
			color: $black;
			text-decoration: underline;
		}
	}

	.icon {
		fill: $navy;
		transition: fill .2s ease;
	}
}
