@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-bottom: 24px;

	@include desktop {
		padding: 24px 10px;
		width: 22%;
		margin-bottom: 0;
	}

	.value {
		font-weight: 700;

		&.notAvailable {
			color: $red;
		}
	}
}
