@import "~/styles/mixins.scss";
.rating {
	display: flex;
	align-items: center;

	.icon {
		margin-right: 2px;
	}

	.counter {
		margin-left: 4px;
		font-size: 14px;
		color: $black55;
	}
}
