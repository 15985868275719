@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;

	@include mobile {
		flex-wrap: nowrap;
	}

	.button {
		width: 100%;
		margin: 0 0 25px;

		@include mobile {
			width: 206px;
			margin: 0 15px 20px 0;
		}
	}
}
