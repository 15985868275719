@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;

	@include small-desktop {
		margin-top: 28px;
	}

	&.isMozaic {
		&.isDirty {
			.zipCode {
				@include small-desktop {
					width: 123px;
				}
			}
		}
	}

	.zipCode {
		width: 125px;

		@include small-desktop {
			width: 104px;
		}
	}
}
