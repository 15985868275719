@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;

	.name {
		font-size: 12px;
		line-height: 14px;
		color: $navy;
		max-width: 140px;

		&:hover {
			color: $green-dark;
		}
	}

	.price {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}
