@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 13px;
	padding-top: 13px;
	border-top: 1px solid $black10;
	display: flex;
	justify-content: space-between;
	gap: 0.25rem;

	.link {
		line-height: 18px;

		.label {
			display: inline-block;
		}
	}
}
