@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;

	&.selected {
		background-color: $black5;
	}

	.name {
		display: flex;
		align-items: center;

		.icon {
			margin-right: 30px;
		}
	}
}
