@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-bottom: 24px;

	@include desktop {
		padding: 24px 10px;
		width: 18%;
		margin-bottom: 0;
	}

	.actions {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 32px;
		border: 1px solid $black10;

		&.isMozaic {
			border-color: $color-grey-600;
			border-radius: 4px;
		}

		.decrementButton,
		.incrementButton {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 100%;
			padding: 0 5px;
			transition: background .2s ease;

			@include desktop {
				width: 24px;
			}

			&:hover {
				background: $green;

				.icon {
					fill: $white;
				}
			}

			&.isMozaic {
				fill: $color-primary-01-600;
				width: 32px;
				height: 32px;
				border: 2px solid $color-primary-01-600;

				&:hover {
					background: $color-primary-01-600;

					.icon {
						fill: $white;
					}
				}

				.icon {
					fill: $color-primary-01-600;
				}
			}
		}

		.incrementButton {
			&.isMozaic {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		.decrementButton {
			&.disabled {
				cursor: not-allowed;

				&:hover {
					background: $white;

					.icon {
						fill: $black10;
					}
				}
			}

			&.isMozaic {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;

				&.disabled {
					background: $color-grey-300;
					border-color: $color-grey-300;

					.icon {
						fill: $color-grey-600;
					}
				}
			}
		}
	}

	.unit {
		display: flex;
		margin-top: 4px;

		// FIXME: https://jira.adeo.com/browse/PLEDEV-6863
		// &.notEnough {
		//	color: $red;
		// }
	}

	.quantityByAreaLabel {
		display: flex;
		flex-direction: column;
		justify-content: center;
		line-height: 15px;
		font-size: 12px;
		margin-top: 5px;

		@include desktop {
			margin-top: 10px;
		}

		.packageQuantityInfo {
			text-align: center;
		}
	}
}
