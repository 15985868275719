@import "~/styles/mixins.scss";
.wrapper {
	width: 104px;
	padding: 14px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: white;
	border: 1px solid $black10;
	color: $navy;
	font-size: 12px;
	line-height: 15px;

	&.isMozaic {
		border-radius: 4px;
		border-color: $black;
		font-size: 14px;
		line-height: 16px;

		&:hover {
			background: $green-kobi-success;
		}
	}

	&.selected {
		background: $green;
		color: $white;
		font-weight: 700;

		&.isMozaic {
			background: $green-kobi;
		}
	}
}
