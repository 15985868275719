@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	.iconWrapper {
		width: 24px;
		height: 24px;
	}

	.description {
		font-size: 12px;
		line-height: 16px;
	}
}
