@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;
	font-size: 12px;
	font-weight: 700;

	&.isMozaic {
		color: $color-grey-600;

		&.isOnDemand {
			color: $red;
		}
	}

	&.isOnDemand {
		color: $red;
	}
}
