@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 8px;

	@include small-desktop {
		margin-top: 0;
		margin-left: auto;
	}

	.price {
		margin-left: 3px;
		font-weight: 700;
	}
}
