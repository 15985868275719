@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	width: inherit;

	&.top {
		align-self: flex-start;
	}

	&.sticky {
		position: fixed;
	}

	&.bottom {
		align-self: flex-end;
	}
}
