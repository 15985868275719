@import "~/styles/mixins.scss";
.wrapper {
	border: 1px solid $navy;
	width: 100%;
	padding: 24px 20px;

	@include tablet {
		padding: 24px 31px;
	}
}
