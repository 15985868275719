@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;

	.rating {
		margin-top: 8px;

		.star {
			&[disabled] {
				cursor: default;
			}

			& + .star {
				margin-left: 4px;
			}

			.icon {
				fill: $black5;
				transition: fill .2s ease;

				&.selected {
					fill: $orange;
				}
			}
		}
	}
}
