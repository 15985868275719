@import "~/styles/mixins.scss";
.wrapper {
	opacity: .7;
	position: relative;

	&:after {
		background-color: rgba(255, 255, 255, 50%);
		width: 100%;
		height: 100%;
		font-family: Turbinado, LMSans, sans-serif;
		color: $green;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		content: attr(data-text);
	}

	&.sm {
		&:after {
			font-size: 16px;

			@include tablet {
				font-size: 32px;
			}
		}
	}

	&.md {
		&:after {
			font-size: 32px;

			@include tablet {
				font-size: 64px;
			}
		}
	}

	&.lg {
		&:after {
			font-size: 64px;

			@include tablet {
				font-size: 128px;
			}
		}
	}

	.withLoader {
		position: absolute;
		top: 50%;
		left: 45%;
	}
}
