@import "~/styles/mixins.scss";
.wrapper {
	padding: 16px 0;
	border-bottom: 1px solid $black10;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	width: 100%;

	@include mobile {
		flex-direction: row;
	}

	.link {
		display: flex;

		.imageWrapper {
			flex-shrink: 0;

			.image {
				object-fit: cover;
				object-position: 0;
			}
		}
	}

	.button {
		margin-left: auto;
		flex-shrink: 0;
		padding: 7px 12px;
		white-space: initial;
		line-height: 17px;
		width: 146px;

		@include desktop {
			height: 40px;
		}
	}
}
