@import "~/styles/mixins.scss";
.link {
	justify-content: space-between;
	display: flex;
	padding: 13px 29px 17px 40px;
	background: $black5;
	border-bottom: 1px solid $black10;
	color: $navy;
	width: 100%;

	&:hover {
		text-decoration: underline;
	}

	.dataWrapper {
		display: flex;
		line-height: 16px;
		text-align: left;

		.icon {
			margin-right: 30px;
		}

		.data {
			flex-direction: column;
		}

		.openingHours {
			margin-top: 7px;
			font-size: 12px;
			white-space: normal;
		}
	}

	.arrowIcon {
		margin-top: 7px;
	}
}
