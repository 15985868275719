@import "~/styles/mixins.scss";
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	background: $black55;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	display: none;

	&.active {
		display: block;
	}
}
