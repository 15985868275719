@import "~/styles/mixins.scss";
.wrapper {
	align-items: center;
	justify-content: center;
	display: flex;
	position: relative;
	margin-top: 10px;

	.form {
		width: 100%;

		.list {
			flex-direction: column;
			display: flex;
			align-items: flex-start;
		}

		.paymentLabel {
			border: 1px solid $black10;
			border-top: none;
			width: 100%;
			padding: 24px 20px;

			@include tablet {
				padding: 24px 31px;
			}

			&.selected {
				border: 1px solid $navy;

				&:first-child {
					border-top: 1px solid $navy;
				}
			}

			&:first-child {
				border-top: 1px solid $black10;
			}
		}
	}

	&.isMozaic {
		.form {
			.paymentLabel {
				border-color: $color-grey-600;

				&:first-child {
					border-top: 1px solid $color-grey-600;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
				}

				&:last-child {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}

				&.selected {
					border: 1px solid $color-primary-01-600;

					&:first-child {
						border-top: 1px solid $color-primary-01-600;
					}
				}
			}
		}
	}
}
