@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	height: 122px;
	max-width: 100%;

	@include tablet {
		margin-right: 0;
	}

	.swiper {
		width: calc(100% + 14px);
		max-width: calc(100% + 14px);
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		min-height: 0;
		min-width: 0;
	}

	.dateTile {
		width: 104px;
		text-align: center;
		font-size: 18px;
		background: $white;
		height: calc((100% - 32px) / 2) !important;
	}
}
