@import "~/styles/mixins.scss";
.wrapper {
	line-height: 20px;
	color: $black65;
	margin-bottom: 24px;

	&.isMozaic {
		color: $color-grey-800;
	}
}
