@import "~/styles/mixins.scss";
.wrapper {
	$padding-value: min(5vw, 70px);

	flex-basis: 100%;
	padding-right: 0;
	border-right: none;

	@include tablet {
		flex-basis: calc(400px + $padding-value);
		padding-right: $padding-value;
		border-right: solid 1px $black10;
	}

	.caption {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: $black65;
	}

	.button {
		width: 100%;
		margin-top: 32px;
	}
}
