@import "~/styles/mixins.scss";
.wrapper {
	&.isMozaic {
		.label {
			color: $color-grey-800;
			font-weight: 400;

			.info {
				font-size: 12px;
				color: $color-grey-600;
			}
		}

		.content {
			border-radius: 4px;
			background: none;
			border: 1px solid $color-grey-600;

			&.focus {
				box-shadow: 0 0 0 2px $white, 0 0 0 4px $color-info-500;
			}

			.prefix {
				display: flex;
				align-items: center;
				gap: 8px;
				font-weight: 600;
				color: $color-grey-900;
				padding: 14px 48px 14px 16px;
			}

			.number {
				line-height: 24px;
				color: $color-grey-900;
				border-color: $color-grey-600;
				padding: 12px;
			}
		}
	}

	.label {
		line-height: 17px;
		margin-bottom: 8px;
		font-weight: 700;
		color: $black65;

		.info {
			font-size: 11px;
			line-height: 16px;
			font-weight: 400;
			white-space: nowrap;

			@include tablet {
				font-size: 14px;
				line-height: 17px;
			}
		}
	}

	.content {
		display: flex;
		background-color: $black10;
		line-height: 18px;

		@include tablet {
			width: 395px;
		}

		.number {
			flex: 1;
			border-left: 1px solid $black10;
		}

		.number,
		.prefix {
			padding: 15px 21px;
			letter-spacing: 1px;
		}
	}
}
