@import "~/styles/mixins.scss";
.wrapper {
	margin-bottom: 8px;

	.label {
		font-size: 14px;
		font-weight: 600;
		color: $black55;

		&.required:after {
			content: " *";
		}

		&.isMozaic {
			font-weight: 400;
			color: $black;

			&.required:after {
				content: none;
			}
		}

		.postfix {
			margin-left: 5px;
			font-size: 12px;
			line-height: 16px;
			color: $color-grey-600;
			white-space: nowrap;
		}
	}
}
