@import "~/styles/mixins.scss";
.wrapper {
	margin: 22px 0;

	@include tablet {
		margin-bottom: 0;
	}

	.list {
		padding-right: 16px;
	}

	.scrollbar {
		margin-bottom: 28px;
	}

	.button {
		margin: 0 auto;
		font-size: 14px;
		color: $navy;
		text-decoration: underline;
		display: block;

		&:hover {
			text-decoration: none;
		}
	}
}
