@import "~/styles/mixins.scss";
.wrapper {
	height: $header-height-mobile;
	align-items: center;
	justify-content: center;
	z-index: 11;
	position: relative;
	width: 100%;
	transition: box-shadow .3s ease;

	@include desktop {
		height: $header-height;
	}

	&.simple {
		height: $header-simple-height-mobile;

		@include desktop {
			height: $header-simple-height;
		}
	}

	&.fixedHeader {
		position: fixed;

		@include desktop {
			height: 80px;
			box-shadow: 0 3px 4px 0 $black10;
		}
	}
}
