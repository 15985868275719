@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include mobile {
		padding: 0;
	}

	.loginButton {
		width: 100%;
		margin: 0;
		padding: 15px;
	}

	.registrationWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		margin-top: 44px;
		padding-top: 31px;
		border-top: 1px solid $black30;

		@include mobile {
			flex-wrap: nowrap;
			padding-top: 24px;
		}

		.question {
			font-size: 16px;
			font-weight: 700;
			color: $black65;
			line-height: 19px;
		}

		.button {
			width: 100%;
			height: 48px;

			@include mobile {
				width: 166px;
				height: 40px;
			}
		}
	}
}
