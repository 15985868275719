@import "~/styles/mixins.scss";
.wrapper {
	.header {
		font-size: 16px;
		color: $black65;

		.store {
			margin-left: 3px;
			font-weight: 700;
		}
	}

	.data {
		margin-top: 16px;
		padding: 16px 0;
		line-height: 19px;
		color: $black65;
		border-top: 1px solid $black10;

		.arrow {
			&.isMozaic {
				display: none;
			}
		}
	}

	.link {
		margin-left: 3px;
	}

	.phoneNumber {
		color: $navy;
	}
}
