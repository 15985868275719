@import "~/styles/mixins.scss";
.wrapper {
	width: 350px;
	padding: 0 16px;

	.caption {
		font-size: 18px;
		color: $black65;
		text-align: center;
	}

	.button {
		width: 100%;
		margin-top: 24px;
	}
}
