@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: $black5;
	padding: 16px;

	&.isMozaic {
		background-color: $color-grey-100;
	}

	@include small-desktop {
		padding: 24px;
	}
}
