@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.radio {
		display: flex;
		align-items: center;
	}

	.data {
		display: flex;
		align-items: center;
		margin-left: 24px;

		@include tablet {
			margin-left: 30px;
		}

		.img {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 44px;
		}

		.name {
			margin-left: 20px;
		}
	}

	.description {
		margin: 20px 0 0;
		max-width: 700px;

		@include tablet {
			margin: 20px 0 0 60px;
		}

		.bankList {
			font-size: 12px;
			margin-top: 16px;
		}

		.text {
			font-size: 12px;
			line-height: 18px;
			color: $black65;
		}
	}

	&.isMozaic {
		color: $color-grey-600;
	}
}
