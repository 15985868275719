@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 16px;

	.caption {
		font-size: 12px;
		color: $navy;
		line-height: 14px;
		/* stylelint-disable-next-line value-no-vendor-prefix */
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: $green;
		}

		@include desktop {
			max-width: 190px;
		}
	}
}
