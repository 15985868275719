@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;

	.caption {
		display: flex;
		margin-right: 3px;
		line-height: 17px;
	}
}
