@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex: 1;

	@include small-desktop {
		justify-content: space-between;
	}

	.contentWrapper {
		display: flex;
		flex: 1;

		.content {
			flex: 1;

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				min-height: 40px;

				@include small-desktop {
					min-height: unset;
				}

				.title {
					line-height: 20px;
					color: $black65;
					margin-right: 32px;

					&.bold {
						font-weight: 800;
					}

					.store {
						margin-left: 6px;
						font-weight: 700;
					}
				}
			}

			.description {
				font-size: 12px;
				line-height: 16px;
				margin-top: 8px;

				&.isMozaic {
					font-size: 14px;
				}
			}
		}

		.iconWrapper {
			display: flex;
			align-items: center;
			height: 40px;

			@include small-desktop {
				height: unset;
			}

			.icon {
				margin-right: 16px;
			}
		}
	}

	.button {
		display: flex;
		align-items: center;
		color: $navy;
		text-decoration: underline;

		&:hover {
			color: $green;
		}

		.icon {
			margin-right: 8px;
		}

		.text {
			white-space: nowrap;
		}
	}

	&.isMozaic {
		.contentWrapper .content {
			.header .title {
				color: $color-grey-999;
			}

			.description {
				color: $color-grey-800;
			}
		}

		.button {
			color: $color-grey-800;
			text-decoration: none;

			.icon {
				fill: $color-primary-01-600;
			}
		}
	}
}
