@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 32px;

	.searchInfo {
		font-size: 14px;
		color: $black65;
	}

	.label {
		margin-top: 25px;
		font-size: 14px;
		font-weight: 600;
		color: $black55;
	}

	.select {
		margin-top: 8px;

		:global {
			.react-select {
				&__menu-notice--no-options {
					text-align: left;
				}

				&__option {
					&:active {
						background-color: $green-dark;
						color: $white;
					}

					&--is-selected {
						background-color: $green;
						color: $white;
					}
				}

				&__indicators {
					svg {
						fill: $navy;
					}
				}
			}
		}
	}
}
