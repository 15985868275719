@import "~/styles/mixins.scss";
.wrapper {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	width: 100%;

	&:focus-visible {
		@include focus-visible;
	}

	.labelWrapper {
		display: flex;
		align-items: center;
		width: 100%;

		&.isMozaic {
			.text {
				color: $black;
			}
		}

		.text {
			margin-left: 8px;
			font-size: 14px;
			color: $gray;
		}

		.rightAccessory {
			margin-left: auto;
			padding-left: 8px;
			white-space: nowrap;
		}
	}

	.radio {
		display: none;
	}
}
