@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100vh;
	padding: 15px;

	.container {
		display: flex;
		flex-direction: column;
		color: $black65;
		padding: 10px;

		.title {
			font-size: 52px;
			line-height: 54px;
			font-weight: 700;
			margin-bottom: 25px;
		}

		.message {
			font-size: 20px;
			line-height: 32px;
			white-space: pre-line;
		}

		.image {
			max-width: 300px;
			margin: 50px auto 0;

			@include desktop {
				max-width: 480px;
			}

			> span {
				// next/image height auto fix
				position: unset !important;
			}

			.img {
				object-fit: contain;
				width: 100% !important;
				position: relative !important;
				height: unset !important;
			}
		}
	}
}
