@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;

	@include tablet {
		max-width: 520px;
		padding: 80px;
	}

	.name {
		font-size: 18px;
		line-height: 21px;
		font-weight: 700;
	}

	.question {
		margin-top: 8px;
		font-size: 14px;
	}

	.actions {
		margin-top: 30px;
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
		}

		.button {
			padding: unset;
			display: flex;
			width: 100%;

			@include tablet {
				width: auto;
				flex: 1;

				&:first-child {
					margin-right: 15px;
				}
			}

			&:last-child {
				margin-top: 24px;

				@include tablet {
					margin-top: 0;
				}
			}
		}
	}
}
