@import "~/styles/mixins.scss";
.wrapper {
	height: 48px;
	background: $white;
	position: relative;
	display: none;

	&:after {
		content: "";
		height: 1px;
		background: $black10;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	@include desktop {
		display: flex;
		margin-top: 0;
		transition: margin-top .2s ease;
		will-change: margin-top;
	}

	&.notVisible {
		margin-top: -48px;
	}
}
