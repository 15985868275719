@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	z-index: 1;
	position: relative;
	justify-content: flex-start;
	padding: 16px 24px 0;

	&:after {
		content: "";
		height: 5px;
		bottom: -5px;
		left: 0;
		background: $white;
		width: 100%;
		position: absolute;
	}

	&.scrollable {
		border-top: 1px solid $navy;
		box-shadow: 0 -1px 4px 0 $black40;
	}

	.summary {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $gray;
		font-size: 20px;

		.cost {
			font-weight: 600;
		}
	}

	.refreshButton {
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: $navy;
		font-size: 12px;
		transition: color .2s ease;

		&:hover {
			color: $green-dark;

			.icon {
				fill: $green-dark;
			}
		}

		.icon {
			margin-right: 7px;
			transition: fill .2s ease;

			&.loading {
				animation: spin 1.5s ease infinite;
			}
		}
	}

	.button {
		padding: 0 18px;
		margin-top: 16px;
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
