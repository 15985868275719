@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;

	.price {
		font-size: 14px;
		font-weight: 600;
	}

	.separator {
		margin: 0 3px;
	}

	.unit {
		font-size: 12px;
	}
}
