@import "~/styles/mixins.scss";
.wrapper {
	$padding-value: min(5vw, 70px);

	flex-basis: 100%;
	padding-right: 0;
	border-right: none;

	@include tablet {
		flex-basis: calc(400px + $padding-value);
		padding-right: $padding-value;
		border-right: solid 1px $black10;
	}

	.caption {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: $black65;
	}

	.description {
		margin-top: 6px;
		font-size: 18px;
		line-height: 20px;
		color: $black65;
	}

	.fields {
		padding-top: 8px;
	}

	.actions {
		margin: 28px 0 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.link {
			font-size: 12px;
			color: $navy;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.button {
		width: 100%;
		margin-top: 32px;
	}

	.alert {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}
