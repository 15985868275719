@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 24px;

	.button {
		width: 100%;
		border: none;

		&:hover {
			border: none;
		}
	}

	.tooltip {
		width: 100%;
	}

	.link {
		text-align: center;
		color: $navy;
		text-decoration: underline;
		margin-top: 21px;

		&:hover {
			text-decoration: none;
		}
	}

	&.isMozaic {
		.link {
			color: $color-grey-999;
		}
	}
}
