@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 40px;
	cursor: auto;
	max-width: 401px;

	@include small-desktop {
		margin-top: 4px;
	}

	.counter {
		margin-top: 8px;
		font-size: 12px;
	}
}
