@import "~/styles/mixins.scss";
.wrapper {
	.button {
		height: 48px;
		align-items: center;
		justify-content: space-between;
		display: flex;
		background: $black5;
		width: 100%;
		padding: 0 29px 0 40px;
		color: $navy;
		border-bottom: 1px solid $black10;

		&:hover {
			text-decoration: underline;
		}

		.name {
			align-items: center;
			display: flex;

			.icon {
				margin-right: 27px;
			}
		}
	}
}
