@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: 100%;

	.loader {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 80px;
	}
}
