@import "~/styles/mixins.scss";
.wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 0 20px;

	.caption {
		font-size: 18px;
		color: $gray;
		padding: 0 20px 16px;
		border-bottom: 1px solid $black10;
	}

	.question {
		margin: 12px 0 15px;
		font-size: 12px;
		color: $black55;
	}
}
