@import "~/styles/mixins.scss";
.button {
	width: 100%;
	padding-right: 8px !important;
	padding-left: 8px !important;
	font-size: 12px !important;
	line-height: 15px !important;
	white-space: pre-line !important;

	@include tablet {
		font-size: 16px !important;
	}
}
