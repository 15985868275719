@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	@include desktop {
		margin-top: 32px;
	}

	:global {
		.swiper {
			&-wrapper {
				display: flex;
				align-items: center;
				margin-left: 2px;
			}

			&-slide {
				width: unset !important;
			}
		}
	}
}
