@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;
	font-weight: 600;
	text-align: center;
	width: 100%;
	display: none;
	margin-top: 24px;

	@include desktop {
		align-items: flex-start;
		overflow: hidden;
		display: flex;
	}

	.product {
		width: 44%;
		text-align: left;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px 0;
		align-items: center;
	}

	.price {
		width: 12%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.quantity {
		width: 22%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.summaryPrice {
		width: 22%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}
}
