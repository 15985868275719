@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 8px;

	.alert {
		display: flex;
		align-items: center;
		padding: 8px 16px;
		opacity: 0;
		transition: opacity .5s ease;

		@include tablet {
			padding-top: 14px;
			padding-bottom: 14px;
		}

		&.isMozaic {
			@include tablet {
				padding: 20px 24px 20px 16px;
			}

			.content {
				color: $black;

				a {
					color: $black;
				}
			}
		}

		&.opacity {
			opacity: 1;
		}

		&.success {
			background: $alert-success;
			color: $alert-success-text;

			.icon {
				fill: $alert-success-text;
			}
		}

		&.danger {
			background: $alert-danger;
			color: $alert-danger-text;

			.icon {
				fill: $alert-danger-text;
			}
		}

		&.warning {
			background: $alert-warning;
			color: $alert-warning-text;

			&.isMozaic {
				background: $color-orange-100;
				border: 1px solid $color-orange-500;
				border-radius: 4px;

				.icon {
					fill: $color-orange-600;
					transform: rotate(180deg);
				}
			}

			.icon {
				fill: $alert-warning-text;
			}
		}

		&.info {
			background: $gray95;
			color: $black65;

			.icon {
				fill: $black65;
			}
		}

		.icon {
			flex-shrink: 0;
		}

		.content {
			font-size: 14px;
			line-height: 17px;
			margin: 0 10px 0 14px;
			flex: 1;

			a {
				color: $navy;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.button {
			margin-left: auto;
		}
	}
}
