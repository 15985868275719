@import "~/styles/mixins.scss";
.wrapper {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: $green;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	top: -8px;
	right: -6px;

	&.bigValue {
		font-size: 10px;
	}
}
