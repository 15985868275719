@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	margin-top: 16px;

	@include tablet {
		width: calc(100% - 48px);
		margin: 0 24px 16px;
	}

	&.isMozaic {
		width: calc(100% - 48px);
		margin: 0 24px 16px;
	}
}
