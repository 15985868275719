@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex: 1;
	width: 100%;
	white-space: break-spaces;

	.group {
		padding: 24px 20px;
		flex: 1;
		display: flex;
		align-items: center;

		@include small-desktop {
			flex-direction: column;
			height: 227px;
			padding: 42px 20px;
		}

		.iconWrapper {
			width: 35px;
			display: flex;
			margin-right: 17px;

			@include small-desktop {
				width: 100%;
				justify-content: center;
				margin-right: 0;
			}
		}

		.data {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;

			@include small-desktop {
				align-items: center;
				margin-top: 25px;
			}

			.name {
				font-weight: 700;
				font-size: 14px;
				color: $black65;

				@include small-desktop {
					font-size: 16px;
					height: 32px;
					white-space: break-spaces;
				}

				@include large-desktop {
					height: auto;
				}
			}

			.description {
				text-align: left;
				font-size: 12px;
				line-height: 18px;
				color: $black55;
				max-width: 187px;
				margin-top: 4px;

				@include small-desktop {
					text-align: center;
					font-size: 14px;
					line-height: 20px;
					margin-top: 10px;
					white-space: break-spaces;
				}
			}
		}
	}

	&.isMozaic {
		.data {
			color: $color-grey-800;

			.description {
				color: $color-grey-800;
			}
		}

		&.selected .data {
			.name,
			.description {
				color: $color-grey-999;
			}
		}
	}
}
