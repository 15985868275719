@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 8px;

	@include desktop {
		margin-top: 0;
	}

	.highlight {
		margin-left: 3px;
		font-weight: 700;
	}
}
