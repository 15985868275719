@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	color: $black65;
	font-size: 12px;
	width: 120px;
	align-items: center;

	@include desktop {
		justify-content: center;
		align-items: center;
		font-size: 14px;
		padding: 24px 10px;
		width: 21%;
		flex-shrink: 0;
	}

	.isMozaic {
		color: $black65;
	}

	.availabilityPointer {
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: $green;
		margin-right: 8px;

		&.isMozaic {
			background: $color-primary-01-600;

			&.notAvailability {
				background: $color-danger-600;
			}

			&.isOnDemand {
				background: $color-orange-500;
			}
		}

		&.notAvailability {
			background: $red;
		}

		&.isOnDemand {
			background: $orange;
		}
	}
}
