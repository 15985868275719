@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: 1px solid $black10;
	margin: -1px 0 24px;
	padding: 18px 24px;

	&.isMozaic {
		border: none;
		margin-bottom: 5px;

		.textBox {
			.valueLabel,
			.value {
				color: $color-grey-900;
			}
		}
	}

	.textBox {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.value,
		.valueLabel {
			font-size: 12px;
			line-height: 18px;
		}

		.value {
			font-weight: 600;
			color: $black65;
		}
	}

	.progressBar {
		margin-top: 7px;
	}
}
