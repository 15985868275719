@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.selectedRewardDescription {
		font-size: 12px;
		line-height: 16px;
		font-weight: 700;
		padding-bottom: 8px;
	}
}
