@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 120px;

	@include desktop {
		width: 22%;
		justify-content: center;
		align-items: center;
		padding: 24px 10px;
		flex-shrink: 0;
	}

	.header {
		display: block;
		margin-bottom: 5px;
		font-size: 12px;
		line-height: 15px;

		@include desktop {
			display: none;
		}
	}

	.price {
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 20px;

		@include desktop {
			margin-bottom: 0;
		}
	}
}
