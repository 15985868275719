@import "~/styles/mixins.scss";
.wrapper {
	font-size: 14px;
	color: $black65;
	font-weight: 700;
	height: 24px;

	@include small-desktop {
		height: 48px;
		font-size: 18px;
	}
}
