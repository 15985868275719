@import "~/styles/mixins.scss";
.wrapper {
	font-size: inherit;
	color: $navy;
	margin-left: 3px;
	text-transform: capitalize;

	&,
	&:hover {
		text-decoration: underline;
	}
}
