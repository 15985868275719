@import "~/styles/mixins.scss";
@import "@mozaic-ds/react/lib/index.css";

.wrapper {
	font-size: 14px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .2s ease;
	white-space: nowrap;

	&:focus-visible {
		@include focus-visible;
	}

	&.normal {
		height: 48px;
		padding: 0 16px;

		@include mobile {
			padding: 0 58px;
		}
	}

	&.small {
		height: 40px;
		padding: 0 16px;

		@include mobile {
			padding: 0 30px;
		}
	}

	&.primary {
		background: $green;
		border: 2px solid $green;
		color: $white;

		&:hover {
			background: $green-dark;
			border: 2px solid $green-dark;
		}

		&.reverse {
			background: transparent;
			color: $green;

			&:hover {
				background: $green-dark;
				border: 2px solid $green-dark;
				color: $white;
			}
		}
	}

	&.secondary {
		background: $navy;
		border: 2px solid $navy;
		color: $white;

		&:hover {
			background: $navy-dark;
			border: 2px solid $navy-dark;
		}

		&.reverse {
			background: transparent;
			color: $navy;

			&:hover {
				background: $navy;
				border: 2px solid $navy;
				color: $white;
			}
		}
	}

	&.basicTag {
		font-weight: 400;
		color: $navy;
		border: 1px solid $black10;

		&.normal,
		&.small {
			padding: 0 25px;

			@include tablet {
				min-width: 144px;
			}
		}

		&:hover {
			background: $navy;
			border: 1px solid $navy;
			color: $white;
		}
	}

	&.inspirationTag {
		font-weight: 400;
		border: 1px solid $teal-light;
		color: $teal-light;

		&.normal,
		&.small {
			padding: 0 25px;

			@include tablet {
				min-width: 144px;
			}
		}

		&:hover {
			background: $teal-light;
			border: 1px solid $teal-light;
			color: $white;
		}
	}

	&.disabled {
		background: $black10;
		border: 2px solid $black10;
		color: $black55;
		cursor: not-allowed;

		&:hover {
			background: $black10;
			border: 2px solid $black10;
		}

		&.reverse {
			background: $white;
			color: $black10;
		}

		&.neutral {
			background-color: inherit;
			border: none;
		}
	}

	&.loading {
		pointer-events: none;

		&.primary,
		&.secondary,
		&.basicTag,
		&.inspirationTag {
			background: transparent;
		}
	}

	&.neutral {
		font-weight: 400;
	}

	.ariaWrapper {
		display: inherit;
		flex-direction: inherit;
		justify-content: inherit;
		align-items: inherit;
		height: inherit;
		width: inherit;
	}
}

.mozaicWrapper {
	display: flex;
	justify-content: center;
	align-items: center !important; // override mc-button

	&.inherit {
		width: inherit;
	}

	&.small {
		height: 40px;
		padding: 0 16px;
		min-height: auto;

		@include mobile {
			padding: 0 30px;
		}
	}

	&.loading {
		pointer-events: none;
	}
}
