@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 8px 0;

	.selectedCodeDescription {
		font-size: 12px;
		line-height: 16px;
		font-weight: 700;
		margin-top: 8px;
	}

	.description {
		font-size: 12px;
		line-height: 16px;
	}

	.successWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		.iconWrapper {
			width: 16px;
			height: 16px;
		}

		.caption {
			font-weight: 400;
		}
	}

	.targetedOfferSuccessWrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.targetedOfferInfoWrapper {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
