@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;

	.formControl {
		height: 130px;
		margin-top: 8px;
		position: relative;

		&.valid,
		&.invalid {
			&:before {
				width: 4px;
				height: calc(100% - 2px);
				position: absolute;
				top: 1px;
				left: 1px;
				content: "";
			}
		}

		&.valid {
			&:before {
				background-color: $green;
			}
		}

		&.invalid {
			&:before {
				background-color: $red;
			}
		}

		.textarea {
			width: 100%;
			height: 100%;
			padding: 16px;
			border: 1px solid $black10;
			background-color: $white;
			resize: none;
			color: $black55;
			font-size: 14px;
			font-weight: 400;

			&[disabled] {
				border-color: $black5;
				background-color: $black5;
			}

			&::placeholder {
				color: $black30;
			}

			&:focus-visible {
				outline: none;
			}

			&.error,
			&.invalid {
				border-color: $red;
			}

			&.valid {
				border-color: $green;
			}

			&.isMozaic {
				border-radius: 4px;
				background: none;
				color: $black;
				line-height: 18px;
			}
		}
	}
}
