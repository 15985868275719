@import "~/styles/mixins.scss";
.wrapper {
	height: 60px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	display: flex;
	padding: 16px 16px 16px 40px;

	.arrow {
		transform: rotate(180deg);
		margin-right: 15px;
	}

	.label {
		font-size: 14px;
		line-height: 20px;
		color: $navy;
	}
}
