@import "~/styles/mixins.scss";
.wrapper {
	&:not(:first-child) {
		margin-top: 40px;

		@include tablet {
			margin-top: 26px;
			padding-top: 26px;
		}
	}

	.caption {
		font-size: 18px;
		font-weight: 600;
		color: $black65;
	}

	.items {
		margin-top: 22px;

		.item {
			display: flex;
			align-items: center;

			& + .item {
				margin-top: 16px;
			}

			.icon {
				flex-shrink: 0;
			}

			.label {
				margin-left: 16px;
				font-size: 14px;
				line-height: 20px;
				color: $black55;
			}
		}
	}
}
