@import "~/styles/mixins.scss";
.wrapper {
	display: block;
	position: relative;

	.menu {
		position: absolute;
		right: 0;

		.dots {
			font-size: 26px;
			color: $navy;
			position: relative;
			right: -6px;
		}
	}

	.menuList {
		position: absolute;
		top: 26px;
		right: 0;
	}
}
