@import "~/styles/mixins.scss";
.wrapper {
	font-size: 18px;
	line-height: 21px;
	font-weight: 700;
	color: $black65;
	margin-bottom: 16px;

	@include tablet {
		margin-bottom: 24px;
	}
}
