@import "~/styles/mixins.scss";
.wrapper {
	font-size: 11px;
	line-height: 17px;
	margin-top: 48px;
	word-break: keep-all;

	&.isMozaic {
		font-size: 12px;
	}

	.text {
		margin-right: 2px;
		display: unset;

		@include mobile {
			display: inline-block;
		}
	}

	.link {
		margin-right: 2px;
		text-decoration: underline;
		white-space: pre-line;

		@include mobile {
			white-space: nowrap;
		}

		&.isMozaic {
			color: $black;
		}
	}
}
