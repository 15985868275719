@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid $black10;
	padding: 24px;
	width: 250px;
	line-height: 20px;
	color: $black65;

	@include small-desktop {
		width: 300px;
	}

	.warning {
		padding: 8px 18px;
		margin: 34px 0 16px;
	}

	.button {
		width: 100%;
	}
}
