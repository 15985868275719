@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 56px;
	font-size: 18px;
	font-weight: 700;
	color: $black65;

	@include tablet {
		margin-top: 72px;
	}
}
