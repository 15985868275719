@import "~/styles/mixins.scss";
.wrapper {
	font-size: 14px;
	line-height: 20px;
	padding: 16px;
	position: absolute;
	top: 40px;
	left: 0;
	margin: 0 16px;
	width: calc(100% - 32px);
	border: solid 1px $navy;
	background: $white;
	z-index: 3;
	min-height: 100px;

	@include desktop {
		width: 100%;
		margin: 0;
		top: 48px;
	}

	&.modal {
		position: relative;
		top: 4px;
		margin: 0;
		width: 100%;
		border: none;

		.noResults {
			.query {
				font-weight: 700;
			}
		}
	}

	&.history {
		padding: 0;
	}

	&.hidden {
		display: none;
	}

	.historyHeader {
		background-color: $white;
	}

	.historyClearButton {
		white-space: nowrap;
		padding-top: 4px;
	}

	.noResults {
		color: $black55;

		.query {
			margin-left: 3px;
		}
	}

	.findByCategory {
		margin-top: 8px;
		color: $navy;

		.category {
			margin-left: 3px;
			font-weight: 700;
		}
	}
}
