@import "~/styles/mixins.scss";
.wrapper {
	min-height: 48px;
	align-items: center;
	padding: 10px 28px 10px 40px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	border-top: 1px solid $black10;
	color: $navy;
	font-size: 16px;
	text-align: left;
	line-height: 22px;

	&:last-child {
		margin-bottom: 9px;
	}

	&:hover {
		text-decoration: underline;
	}

	&.withoutIcon {
		font-size: 14px;
	}

	&.leaf {
		padding-left: 62px;
	}

	.name {
		display: flex;
		align-items: center;
		white-space: break-spaces;
	}

	.icon {
		margin-right: 23px;
	}

	.arrow {
		flex-shrink: 0;
		margin-left: 30px;
	}
}
