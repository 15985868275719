@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	font-size: 12px;
	line-height: 18px;
	color: $black55;

	.link {
		color: $navy;

		&:hover {
			text-decoration: underline;
		}
	}
}
