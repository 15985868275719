@import "~/styles/mixins.scss";
.item {
	height: 48px;
	align-items: center;
	display: flex;
	width: 100%;
	color: $navy;
	border-top: 1px solid $black10;

	&:first-child {
		border-top: none;
	}

	&:hover {
		.link {
			text-decoration: underline;
			color: unset;
		}
	}

	&.promotion {
		color: $red-dark;
	}

	&.selected {
		background-color: $black5;
		pointer-events: none;
	}

	.link {
		width: 100%;
		padding: 0 29px 0 40px;
		height: 100%;
	}
}
