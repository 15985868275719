@import "~/styles/mixins.scss";
.wrapper {
	&.isMozaic {
		.cityControls,
		.addressNumbersControls {
			align-items: flex-end;

			.control {
				@include tablet {
					&:first-child {
						max-width: none;
					}
				}
			}
		}
	}

	.addressNumbersControls {
		display: flex;

		.control {
			width: calc(50% - 8px);

			&:last-child {
				margin-left: 16px;
			}
		}
	}

	.cityControls {
		@include tablet {
			display: flex;
		}

		.control {
			@include tablet {
				&:first-child {
					max-width: 125px;
				}

				&:last-child {
					width: 100%;
					margin-left: 16px;
				}
			}
		}
	}
}
