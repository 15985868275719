@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px 10px;
	width: 12%;
	flex-shrink: 0;
	text-align: center;

	.price {
		font-weight: 600;
		white-space: nowrap;

		&.promotion {
			color: $red;
		}
	}
}
