@import "~/styles/mixins.scss";
.wrapper {
	.slide {
		width: calc(50% - 8px);
		margin-right: 0;

		@include tablet {
			width: 256px;
		}
	}
}
