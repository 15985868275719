@import "~/styles/mixins.scss";
.wrapper {
	padding: 16px 0;
	font-size: 12px;
	line-height: 14px;
	color: $black55;

	@include tablet {
		padding: 0 22px;
	}

	.signInButton {
		width: 100%;
		margin: 24px 0 16px;
		padding: 12px;
	}

	.mainContainer {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}
