@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	line-height: 20px;

	.title {
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 5px;
	}
}
