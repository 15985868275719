@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	margin-bottom: 30px;
	column-gap: 8px;

	@include tablet {
		margin-bottom: 40px;
		column-gap: 32px;
		justify-content: space-between;
	}
}
