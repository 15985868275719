@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: auto;
	position: relative;
	display: inline-block;

	.image {
		object-fit: contain;
		width: 100%;
		position: relative;
		height: unset;
	}

	.label {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(50%);

		&.extraBottom {
			bottom: 24px;
		}
	}
}
