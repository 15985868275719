@import "~/styles/mixins.scss";
.modal {
	padding: 0;

	.content {
		max-height: 90vh;
		overflow-y: auto;

		.scroll {
			padding: 88px 48px;

			@include small-desktop {
				padding: 88px 98px;
			}
		}

		.actions {
			margin-right: -16px;
			margin-left: -16px;
		}
	}
}
