@import "~/styles/mixins.scss";
.section {
	transition: all .25s linear;
	width: 100%;
	margin-right: 0;
	border-bottom: 1px solid $white10;

	@include small-desktop {
		margin-right: 45px;
		width: auto;
		border-bottom: 0;
	}

	&.sectionOpen {
		.headerWrapper {
			.icon {
				transform: rotate(270deg);
			}
		}
	}

	.headerWrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 16px;
		cursor: pointer;

		@include small-desktop {
			cursor: auto;
		}

		.header {
			font-size: 16px;
			font-weight: 600;
			margin: 16px 0;

			@include small-desktop {
				margin: 0 0 22px;
			}
		}

		.icon {
			transition: transform .2s ease;
			width: 8px;
			transform: rotate(90deg);

			@include small-desktop {
				display: none;
			}
		}
	}

	.items {
		padding-bottom: 16px;
	}

	.collapseContent {
		transition: height 500ms;
	}
}
