@import "~/styles/mixins.scss";
/* stylelint-disable selector-class-pattern */

.wrapper {
	:global {
		.react-datepicker-popper {
			.react-datepicker {
				padding: 16px;
				border: 1px solid $black10;
				border-radius: 0;

				&__triangle {
					&:before {
						border-bottom-color: $black10;
						border-top-color: $black10;
					}

					&:after {
						border-bottom-color: $white;
					}
				}

				&__header {
					border: 0;
					background: none;
					text-transform: capitalize;
				}

				&__current-month {
					margin-bottom: 24px;
					font-size: 16px;
					font-weight: 700;
					line-height: normal;
					color: $black65;
				}

				&__navigation {
					top: 16px;

					&--previous {
						left: 16px;
					}

					&--next {
						right: 16px;
					}
				}

				&__navigation-icon {
					&:before {
						border-color: $black65;
						height: 7px;
						width: 7px;
					}
				}

				&__day-names {
					border: 0;
					text-transform: capitalize;
				}

				&__day,
				&__day-name {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 40px;
					margin: 0;
					border-radius: 0;
					color: $black65;
					font-size: 14px;
				}

				&__day-name {
					font-size: 12px;
				}

				&__day {
					&--selected,
					&--keyboard-selected {
						background-color: $green;
						font-weight: 700;
						color: $white;
					}

					&--outside-month {
						color: $black20;
					}
				}
			}
		}
	}
}
