@import "~/styles/mixins.scss";
.modal {
	overflow-y: auto;
}

.wrapper {
	max-width: 400px;

	.submit {
		margin-top: 20px;

		@include tablet {
			max-width: 192px;
			margin-top: 24px;
		}
	}

	.recaptcha {
		margin-top: 18px;
	}
}
