@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@include small-desktop {
		flex-direction: row;
		justify-content: flex-end;
	}

	.button {
		display: flex;
		align-items: center;

		&:first-child {
			margin-right: 0;

			@include mobile {
				margin-right: 64px;
			}
		}

		&.isMozaic {
			.caption {
				color: $black;
				text-decoration: underline;

				&:hover {
					color: $color-secondary-green-500;
				}
			}
		}

		.icon {
			margin-right: 6px;
		}

		.caption {
			color: $navy;
			font-size: 12px;

			@include mobile {
				font-size: 14px;
			}

			&:hover {
				color: $green-dark;
			}
		}
	}

	.actionButtonsContainer {
		display: flex;
		justify-content: space-between;
	}
}
