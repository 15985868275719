@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;

	.select {
		margin-top: 8px;

		:global {
			.react-select {
				&__option {
					&:active {
						background-color: $color-secondary-green-500;
						color: $white;
					}

					&--is-selected {
						background-color: $color-secondary-green-500;
						color: $white;
					}
				}
			}
		}

		&:focus-visible {
			outline: none;
		}

		&.disabled {
			:global(.react-select__dropdown-indicator) {
				display: none;
			}
		}

		&.error {
			:global(.react-select__control) {
				border-color: $red;
			}
		}

		&.isMozaic {
			:global {
				.react-select__control {
					border-radius: 4px;
					border-color: $black;

					&--is-focused {
						box-shadow: unset;
					}
				}

				.react-select__indicator {
					color: $black;
				}

				.react-select__menu {
					border-radius: 4px;
					border-color: $black;
				}

				.react-select__option {
					border-radius: 4px;

					&:active {
						background-color: $color-secondary-green-500;
						color: $white;
					}

					&--is-selected {
						background-color: $color-secondary-green-500;
						color: $white;
					}
				}
			}
		}
	}
}
