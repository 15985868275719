@import "~/styles/mixins.scss";
.wrapper {
	padding: 16px 0;
	font-size: 12px;
	line-height: 14px;
	color: $black55;

	@include tablet {
		padding: 16px 24px;
	}
}
