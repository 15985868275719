@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	background: $black5;
	min-height: 48px;
	padding: 0 28px 0 40px;
	width: 100%;
	border-top: 1px solid $black10;
	color: $navy;
	font-size: 16px;
	text-align: left;
	line-height: 14px;
	font-weight: 700;
}
