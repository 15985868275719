@import "~/styles/mixins.scss";
.wrapper {
	border: 2px solid $color-primary-01-600;
	padding: 15px 17px 17px;
	background: $white;
	border-radius: 0;
	font-size: 14px;
	line-height: 18px;
	color: $black65;
	max-width: 250px;
	box-shadow: none;

	&[data-popper-placement*="bottom"] {
		.arrow {
			&:before,
			&:after {
				border-width: 0 8px 8px;
			}

			&:before {
				border-color: transparent transparent $color-primary-01-600 transparent;
				top: -3px;
			}

			&:after {
				border-color: transparent transparent $white transparent;
			}
		}
	}

	&[data-popper-placement*="top"] {
		.arrow {
			&:before,
			&:after {
				border-width: 8px 8px 0;
			}

			&:before {
				border-color: $color-primary-01-600 transparent transparent transparent;
				top: 3px;
			}

			&:after {
				border-color: $white transparent transparent transparent;
			}
		}
	}

	&[data-popper-placement*="right"] {
		.arrow {
			margin-left: -13px;

			&:before,
			&:after {
				border-width: 8px 8px 8px 0;
			}

			&:before {
				border-color: transparent $color-primary-01-600 transparent transparent;
			}

			&:after {
				border-color: transparent $white transparent transparent;
				left: 7px;
				top: 0;
			}
		}
	}

	&[data-popper-placement*="left"] {
		.arrow {
			margin-right: -13px;

			&:before,
			&:after {
				border-width: 8px 0 8px 8px;
			}

			&:before {
				border-color: transparent transparent transparent $color-primary-01-600;
			}

			&:after {
				border-color: transparent transparent transparent $white;
				left: 1px;
			}
		}
	}

	.arrow {
		height: 16px;
		width: 16px;
	}
}
