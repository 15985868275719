@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 53px;
	height: inherit;
	border: 1px solid $black10;
	border-width: 0 1px;

	@include desktop {
		width: 42px;
	}

	.input {
		font-size: 14px;
		height: 18px;
		width: 100%;
		color: $black65;
		outline: none;
		border: none;
		text-align: center;
		background: transparent;

		// FIXME: https://jira.adeo.com/browse/PLEDEV-6863
		// &.notEnough {
		//	color: $red;
		// }
	}
}
