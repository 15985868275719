@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	margin-top: auto;

	.unit {
		margin: 3px 22px 0 0;
		display: flex;
		align-items: center;

		.decrementButton,
		.incrementButton {
			height: 19px;
			display: flex;
			align-items: center;

			&:hover {
				.icon {
					fill: $navy;
				}
			}

			.icon {
				transition: fill .2s ease;
			}
		}

		.decrementButton {
			padding-right: 5px;
			margin-right: 10px;
			border-right: 1px solid $black5;
		}

		.incrementButton {
			padding-left: 5px;
			margin-left: 5px;
			border-left: 1px solid $black5;
		}

		.value {
			font-size: 12px;
			color: $black65;
		}
	}

	.transfer {
		display: flex;
		align-items: center;

		&:hover {
			.label {
				color: $green-dark;
			}
		}

		.label {
			font-size: 12px;
			color: $navy;
			margin-right: 3px;
		}
	}

	.remove {
		display: flex;
		margin-left: auto;

		&:hover {
			.icon {
				fill: $red;
			}
		}

		.icon {
			transition: fill .2s ease;
		}
	}
}
