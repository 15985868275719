@import "~/styles/mixins.scss";
.wrapper {
	.loaderWrapper {
		flex: 1;
		align-items: center;
		justify-content: center;
		display: flex;
		height: 220px;
	}

	.header {
		margin: 0;
	}

	.content {
		margin: 0 -16px;
		position: relative;

		@include small-desktop {
			margin: 0;
		}

		.carousel {
			:global(.swiper) {
				padding: 0 16px;

				@include small-desktop {
					padding: 0;
				}
			}

			:global(.swiper-pagination) {
				display: none;
			}

			.slide {
				width: 250px;
				margin-top: 32px;

				@include small-desktop {
					margin-top: 40px;
					width: 300px;
				}
			}
		}
	}
}
