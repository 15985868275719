@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	margin-top: 60px;

	@include tablet {
		width: 304px;
		margin-top: 0;
	}

	.skipAnchor {
		&:focus-visible {
			box-shadow: none;
		}
	}

	.loaderWrapper {
		height: 300px;
		background-color: $black5;
		align-items: center;
		justify-content: center;
		display: flex;
	}

	.content {
		&.hidden {
			display: none;
		}
	}
}
