@import "~/styles/mixins.scss";
.content {
	opacity: 0;
	visibility: hidden;
	background: $white;
	border: 1px solid $navy;
	padding: 18px 0;
	z-index: 2;

	&.active {
		opacity: 1;
		visibility: visible;

		@include low-height-resolution {
			overflow-y: auto;
			max-height: 500px;
		}
	}

	&.mobile {
		right: 0;
		top: 0;
		position: fixed;
		background: $white;
		z-index: 3;
		border: none;
		border-left: 2px solid $green;
		border-bottom: 2px solid $green;
		padding: 0 0 26px;
	}
}
