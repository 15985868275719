@import "~/styles/mixins.scss";
.wrapper {
	font-size: 12px;
	line-height: 14px;
	color: $black55;
	margin-top: 8px;
	margin-bottom: 16px;

	@include tablet {
		padding: 0 24px;
		margin-top: 0;
	}
}
