@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
	border-bottom: 1px solid $black10;

	.title {
		font-size: 24px;
		font-weight: 600;
		padding: 7px 0;
		color: $black65;
		position: relative;
		top: 1px;
		width: fit-content;
		line-height: 24px;

		@include tablet {
			padding-bottom: 16px;
		}

		&.small {
			padding-bottom: 12px;
			font-size: 16px;

			@include tablet {
				padding-bottom: 14px;
				font-size: 18px;
			}
		}

		&.green {
			border-bottom: 1px solid $green;
		}

		&.navy {
			border-bottom: 1px solid $navy;
		}

		&.red {
			border-bottom: 1px solid $red;
		}

		&.tealLight {
			border-bottom: 1px solid $teal-light;
		}

		.label {
			font-weight: 400;
		}
	}

	.counter {
		display: inline-block;
		margin-left: 5px;
		color: $green;
	}

	.link {
		display: flex;
		align-items: center;
	}

	&.isMozaic {
		border-bottom: none;
	}
}
