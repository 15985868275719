@import "~/styles/mixins.scss";
.wrapper {
	display: block;
	flex: 1;
	max-width: 975px;

	&:nth-child(2) {
		margin-top: -20px;
	}

	@include desktop {
		display: flex;
		flex-direction: column;
		border-top: none;

		&:nth-child(2) {
			margin-top: 24px;
		}
	}

	.skipContentWrapper {
		position: relative;
	}
}
