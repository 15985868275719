@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	padding: 16px 0;

	@include tablet {
		padding: 0 24px 6px;

		&:last-of-type {
			padding-right: 0;
		}
	}

	& + .wrapper {
		border-top: 1px solid $black10;

		@include tablet {
			border-top: none;
			border-left: 1px solid $black10;
		}
	}

	.button {
		font-size: 14px;
		line-height: 14px;
		color: $navy;
		margin-left: 3px;

		&:hover {
			color: $green;
		}
	}
}
