@import "~/styles/mixins.scss";
.wrapper {
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100% !important;
	min-width: 80px;

	@include tablet {
		flex-direction: row;
	}

	&[disabled] {
		cursor: text;
	}

	.title {
		color: $black65;
		margin: 8px 0 0;
		text-align: center;
		font-size: 10px;
		line-height: 12px;

		@include tablet {
			margin: 0 0 0 20px;
			font-size: 14px;
			line-height: 16px;
		}

		&.active {
			color: $green;
		}

		&.isMozaic {
			color: $color-grey-600;

			&.active {
				color: $color-secondary-green-500;
			}
		}
	}
}
