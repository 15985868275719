@import "~/styles/mixins.scss";
.item {
	padding: 0 20px;
	white-space: nowrap;
	display: flex;
	height: 100%;
	align-items: center;

	&.promotion {
		color: $red-dark;
	}

	&.selected {
		.link {
			&:after,
			&:before {
				border-color: $navy;
			}
		}
	}

	.link {
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;

		&:focus-visible {
			@include focus-visible;
		}

		&:after,
		&:before {
			content: "";
			width: 100%;
			position: absolute;
			left: 0;
			transition: all .2s ease;
		}

		&:before {
			bottom: 0;
			border-bottom: 1px solid transparent;
		}

		&:after {
			bottom: 1px;
			border-bottom: 1px solid transparent;
		}

		&:hover {
			color: unset;

			&:after,
			&:before {
				border-color: $navy;
			}
		}
	}
}
