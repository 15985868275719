@import "~/styles/mixins.scss";
.wrapper {
	.navButton {
		position: absolute;
		top: 5px;
		z-index: 100;
		transform: translateX(-100vw);
		transition: transform 0s;

		&:focus {
			transform: translateX(5px);
		}

		&:focus-visible {
			transition: box-shadow 0s;
		}
	}
}
