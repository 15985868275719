@import "~/styles/mixins.scss";
.wrapper {
	position: relative;

	.button {
		position: absolute;
		right: 16px;
		top: calc(50% + 2px);
	}
}
