@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	color: $black55;
	margin-bottom: 15px;
	row-gap: 15px;

	.message {
		line-height: 15px;
	}

	.additionalMessage {
		line-height: 15px;
		width: 100%;
	}
}
