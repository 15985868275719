@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	@include tablet {
		display: flex;
		justify-content: space-between;
	}

	.button {
		width: 100%;

		@include tablet {
			width: calc(50% - 8px);
		}

		& + .button {
			margin-top: 24px;

			@include tablet {
				margin-top: 0;
				margin-left: 16px;
			}
		}
	}
}
