@import "~/styles/mixins.scss";
.wrapper {
	.label {
		display: flex;
		white-space: nowrap;

		.mainLabel {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&.isMozaic {
				font-size: 14px;
				line-height: 18px;
				font-weight: 400;
				color: $color-grey-800;
			}
		}

		.additionalLabel {
			width: 100%;
			font-size: 13px;
		}

		.tooltip {
			margin: 0 0 8px 5px;
		}
	}

	.formControl {
		position: relative;

		&.valid,
		&.invalid {
			&:before {
				width: 4px;
				height: calc(100% - 2px);
				position: absolute;
				top: 1px;
				left: 1px;
				content: "";
			}

			&.isMozaic:before {
				content: none;
			}
		}

		&.valid {
			&:before {
				background-color: $green;
			}
		}

		&.invalid {
			&:before {
				background-color: $red;
			}
		}

		.icon {
			right: 8px;
			position: absolute;
		}

		.inputWrapper {
			display: flex;
			align-items: center;
			width: 100%;
			height: 48px;
			background-color: $white;
			padding: 16px 0;
			border: 1px solid $black10;

			&.isMozaic {
				border-radius: 4px;
				background: none;
				border-color: $color-grey-600;

				&.focus {
					box-shadow: 0 0 0 2px $white, 0 0 0 4px $color-info-500;
				}
			}

			&.error,
			&.invalid {
				border: 1px solid $red;

				&.isMozaic {
					border: 1px solid $color-danger-600;

					&.isPasswordType {
						border: 1px solid $color-grey-600;
					}
				}
			}

			&.valid {
				border: 1px solid $green;

				&.isMozaic {
					border: 1px solid $green-kobi-primary;

					&.isPasswordType {
						border: 1px solid $color-grey-600;
					}
				}
			}

			.prefix {
				border-right: 1px solid $gray90;
				padding: 5px 10px;
			}

			.input {
				width: inherit;
				padding: 14px;
				border: none;
				font-size: 14px;
				font-weight: 400;
				color: $black55;

				&[disabled] {
					border-color: $black5;
					background-color: $black5;
				}

				&[type="password"]:-ms-reveal,
				&[type="password"]:-ms-clear {
					display: none;
				}

				&::placeholder {
					color: $black30;
				}

				&:focus-visible {
					@include focus-visible;

					z-index: unset;
				}

				&.focused {
					box-shadow: unset;
				}

				&.error,
				&.invalid {
					border-color: $red;

					&.isMozaic {
						border-color: $color-danger-600;
					}
				}

				&.valid {
					border-color: $green;

					&.isMozaic {
						border-color: $green-kobi-primary;

						&.isPasswordType {
							border: 1px solid $color-grey-600;
						}
					}
				}

				&.isMozaic {
					font-size: 16px;
					color: $black;
					background: none;

					&::placeholder {
						color: $color-grey-600;
					}

					&.isPasswordType {
						padding-right: 75px;
					}
				}

				&.isMozaicDisabled {
					background: $gray-light-kobi;
					border-color: $gray-light-kobi;
					color: $gray-kobi-600;
					cursor: not-allowed;
				}

				&.hasMozaicIcon {
					padding-right: 38px;
				}
			}
		}

		.passwordButton {
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
		}
	}
}
