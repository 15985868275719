@import "~/styles/mixins.scss";
.wrapper {
	padding: 74px;
	display: flex;
	flex-direction: column;

	@include desktop {
		min-width: 1120px;
	}

	.content {
		overflow-y: auto;
		height: 100%;

		.subtitle {
			line-height: 20px;
			font-size: 14px;
			max-width: 516px;
		}

		.actions {
			display: flex;
			margin-top: 64px;

			.button {
				width: 100%;

				@include mobile {
					width: auto;
					min-width: 212px;
				}

				&:first-child {
					margin-right: 16px;
				}
			}
		}
	}
}
