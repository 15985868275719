@import "~/styles/mixins.scss";
.hamburgerButton {
	align-items: center;
	display: flex;
	flex-direction: column;

	@include desktop {
		display: none;
	}

	&:hover {
		.icon {
			fill: $green-dark;
		}

		.label {
			color: $green-dark;
		}
	}

	.icon {
		transition: fill .2s ease;
	}

	.label {
		transition: color .2s ease;
		color: $navy;
		font-size: 8px;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 3px;
	}
}
