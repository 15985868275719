@import "~/styles/mixins.scss";
.wrapper {
	width: 323px;
	height: 47px;
	background: $navy;
	background: linear-gradient(45deg, $white 54%, $navy 54%);
	color: $white;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	padding-right: 25px;
	transition: background .2s ease;

	&.megaMenuVisible {
		background: $green-dark;
		background: linear-gradient(45deg, $white 54%, $green-dark 54%);

		.button {
			&:hover {
				.icon {
					fill: $white;
				}

				.label {
					color: $white;
				}
			}
		}
	}

	.button {
		display: flex;
		align-items: center;
		height: 100%;

		&:hover {
			.icon {
				fill: $green-dark;
			}

			.label {
				color: $green-dark;
			}
		}

		.label {
			margin-left: 10px;
			transition: color .2s ease;
		}

		.icon {
			transition: fill .2s ease;
		}
	}
}
