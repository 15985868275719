@import "~/styles/mixins.scss";
.wrapper {
	.content {
		transition: all .2s ease;
		color: $black55;

		// dangerouslySetInnerHTML content
		strong {
			font-weight: 600;
		}

		// dangerouslySetInnerHTML content
		a {
			color: $green;

			&:focus-visible {
				@include focus-visible;
			}
		}
	}

	.slideDown {
		font-size: 11px;
		line-height: 14px;
	}
}
