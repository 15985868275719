@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: $navy;

	.itemWrapper {
		display: flex;
		align-items: center;
		height: 45px;
		width: 100%;
		border-top: 1px solid $black10;

		.button {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-left: 41px;
			width: 100%;
			height: 100%;

			&:hover {
				text-decoration: underline;
				color: $navy;
			}
		}
	}

	.logoutWrapper {
		width: 100%;
		border-top: solid 4px $black10;

		.logoutButton {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			height: 68px;
			padding-left: 41px;

			&:hover {
				text-decoration: underline;
				color: $navy;
			}
		}
	}
}
