@import "~/styles/mixins.scss";
.wrapper {
	height: 60px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	background: $white;
	padding: 16px;
	border-bottom: 1px solid $black10;
	margin-bottom: 20px;
}
