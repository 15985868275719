@import "~/styles/mixins.scss";
.wrapper {
	border-bottom: 1px solid $black10;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 24px;

	@include desktop {
		margin-top: 0;
	}

	.data {
		display: flex;
		flex-direction: column;

		@include desktop {
			flex-direction: row;
		}
	}
}
