@import "~/styles/mixins.scss";
.wrapper {
	.content {
		@include tablet {
			width: 365px;
		}

		.description {
			line-height: 20px;

			& + .description {
				margin-top: 24px;
			}

			.highlight {
				font-weight: 700;
				margin: 0 3px;
			}
		}

		.control {
			margin-top: 32px;
		}
	}
}
