@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	.link {
		display: inline;
		margin-left: 5px;
		color: $navy;

		&:hover {
			text-decoration: underline;
		}
	}
}
