@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;

	.caption {
		font-size: 20px;
		color: $black65;
		font-weight: 700;
	}

	.info {
		line-height: 19px;
		margin: 19px 0;
	}
}
