@import "~/styles/mixins.scss";
.wrapper {
	font-size: 18px;
	font-weight: 700;
	line-height: 21px;
	margin-bottom: 32px;

	&.isMozaic {
		color: $black;
	}
}
