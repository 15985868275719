@import "~/styles/mixins.scss";
.wrapper {
	$padding-value: min(5vw, 70px);

	flex-basis: auto;
	margin-top: 48px;
	padding-top: 48px;
	padding-left: 0;
	border-top: 1px solid $black10;

	@include tablet {
		flex-basis: calc(425px + $padding-value);
		margin-top: 0;
		padding-top: 0;
		padding-left: $padding-value;
		border-top: none;
	}

	.caption {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: $black65;
	}

	.description {
		margin-top: 6px;
		font-size: 14px;
		line-height: normal;
		color: $black65;
	}

	.profits {
		margin-top: 22px;
	}

	.button {
		width: 100%;
		max-width: unset;
		margin-top: 40px;

		@include tablet {
			max-width: 400px;
		}
	}
}
