@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	line-height: 17px;
	color: $navy;
	transition: color .3s ease;
	display: flex;
	align-items: center;

	&:hover {
		color: $green;
	}

	&.isMozaic {
		color: $color-primary-01-600;
		text-decoration: underline;

		.icon {
			fill: $color-primary-01-600;
		}
	}

	.icon {
		margin-right: 8px;
	}
}
