@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin: 20px 0 0;

	@include tablet {
		margin: 20px 125px 0 0;
	}

	@include small-desktop {
		flex-basis: 450px;
		flex-shrink: 0;
	}
}
