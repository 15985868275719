@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	text-align: left;
	min-width: 272px;
	width: max-content;
	color: $navy;
	text-decoration: underline;

	@include small-desktop {
		text-align: end;
	}

	&.isMozaic {
		color: $black;
	}

	&:hover {
		text-decoration: none;
	}

	.icon {
		margin-right: 8px;
		transform: rotate(180deg);
	}
}
