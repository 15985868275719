@import "~/styles/mixins.scss";
.wrapper {
	&.editButton {
		transition: color .3s ease;
		color: $navy;
		text-decoration: underline;
		margin-top: 43px;
		text-align: left;
		align-self: self-start;

		&:hover {
			color: $green;
		}
	}

	&.selectButton {
		margin-top: 8px;
	}

	&.isMozaic {
		color: $black;
	}
}
