@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	@include tablet {
		flex-direction: row;
		margin-left: -20px;
	}

	&.isMozaic {
		@include tablet {
			margin-left: -43px;
		}
	}

	.img {
		position: relative;
		width: 40px;
		height: 27px;
		margin-left: 24px;
	}
}
