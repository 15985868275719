@import "~/styles/mixins.scss";
.wrapper {
	padding: 0 0 16px;

	@include tablet {
		padding: 0 24px 16px;
	}

	.caption {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		color: $black65;
	}

	.content {
		.inaccessibility {
			margin-top: 16px;
			font-size: 14px;
			line-height: 15px;
			color: $black55;

			@include tablet {
				font-size: 12px;
			}

			.link {
				height: auto;
				padding: 0;
				font-size: 12px;
				color: $green;
				display: inline;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.icon {
			margin-top: 3px;
		}
	}
}
