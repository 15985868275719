@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 56px;

	@include tablet {
		margin-top: 72px;
	}

	.caption {
		font-size: 18px;
		font-weight: 700;
		color: $black65;
	}

	.radio {
		display: flex;
		flex-direction: column;

		.radioLabel {
			margin-top: 24px;

			&:first-of-type {
				margin-top: 4px;
			}
		}
	}
}
