@import "~/styles/mixins.scss";
.closeButton {
	display: flex;
	flex-direction: column;

	&:hover {
		.label {
			color: $green;
		}

		.icon {
			fill: $green;
		}
	}

	.label {
		font-size: 8px;
		text-transform: uppercase;
		font-weight: 700;
		color: $navy;
		transition: color .2s ease;
	}

	.icon {
		transition: fill .2s ease;
	}
}
