@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px 10px;
	width: 22%;
	flex-shrink: 0;

	.price {
		font-weight: 700;
	}
}
