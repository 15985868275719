@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 8px;
	background-color: $black5;

	&.isMozaic {
		height: 4px;

		.fill {
			background-color: $color-primary-01-600;
		}
	}

	.fill {
		background-color: $green;
		transition: all .2s ease;
		height: 100%;
	}
}
