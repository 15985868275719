@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	height: 48px;
	align-items: center;
	width: 100%;
	color: $navy;
	font-size: 16px;

	&.active {
		background: $white;
	}

	.icon {
		margin-right: 26px;
		margin-left: 48px;
		transition: all .2s ease;
	}
}
