@import "~/styles/mixins.scss";
.wrapper {
	display: inline-block;
	padding: 30px 35px 10px 0;
	font-size: 24px;
	font-weight: 600;
	border-top: 1px solid $green;
	color: $black65;

	@include small-desktop {
		padding: 34px 10px 35px;
	}
}
