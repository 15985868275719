@import "~/styles/mixins.scss";
.container {
	height: 100%;
	display: none;

	@include desktop {
		display: block;
	}

	.wrapper {
		height: 100%;
		padding: 0 13px;

		&.active {
			background-color: $navy;

			// overriding PreferredStore component styles without passing props
			&:hover {
				svg {
					fill: $white;
				}

				span {
					color: $white !important;
				}
			}
		}
	}
}
