@import "~/styles/mixins.scss";
.wrapper {
	.loader {
		margin-top: 16px;
	}

	.radio {
		flex-direction: column;

		&.head {
			margin-top: 0;
		}

		.label {
			& * {
				font-size: 12px !important;
				line-height: 16px;
			}

			& + .label {
				margin-top: 16px;
			}
		}
	}

	.container {
		.cardNumberWrapper {
			margin-top: 0;

			.label {
				label {
					font-size: 12px;
					font-weight: 400;
				}
			}

			.icon {
				display: none;
			}
		}

		.warning {
			margin-top: 8px;
			font-size: 11px;
			color: $black55;
		}

		.error {
			margin-top: 8px;
			font-size: 11px;
			color: $red;

			.link {
				color: $navy;
			}
		}

		.rightBottomAccessory {
			top: 34px;
			right: 12px !important;

			&.isMozaic {
				top: 27px;
				right: 1px !important;
			}

			.applyButton,
			.removeButton {
				height: 25px;
			}

			.applyButton {
				padding-right: 20px;
				padding-left: 20px;
			}

			.removeButton {
				padding-right: 5px;
				padding-left: 5px;
			}

			.isMozaic {
				height: 46px;
				align-items: center;
				font-weight: 700;
				font-size: 14px;
				color: $color-grey-900;
				border: none;
				border-radius: 0 4px 4px 0;
				border-left: 1px solid $color-grey-600;
				padding: 0 14px 0 15px;

				&.removeButton {
					padding-top: 3px;
					border-left: none;
					background-color: $white;
				}
			}
		}

		.collectWrapper {
			margin-bottom: 16px;

			.rewardsLabel {
				margin-top: 16px;
				font-size: 12px;
			}
		}

		.impossibleRecharge {
			margin-top: 8px;
			font-size: 11px;
			line-height: 14px;

			.error {
				color: $red;
			}
		}

		.cardBlockedWrapper {
			margin-top: 8px;
			font-size: 11px;
			line-height: 14px;

			.cardBlocked {
				color: $red;
			}

			.link {
				color: $navy;
				transition: color 0.3s ease-in-out;

				&:hover {
					color: $green;
				}
			}
		}
	}
}
