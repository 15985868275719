@import "~/styles/mixins.scss";
.wrapper {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;

	.scrollButton {
		position: relative;
		background: linear-gradient(135deg, transparent 50%, $green 50%);
		height: 93px;
		width: 93px;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;

		&:hover {
			cursor: pointer;

			.icon {
				fill: $navy;
			}
		}

		&.visible {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

		.icon {
			position: absolute;
			bottom: 15px;
			right: 25px;
			transition: fill .3s ease;
		}
	}
}
