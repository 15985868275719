@import "~/styles/mixins.scss";
.wrapper {
	height: 100%;
	position: relative;
	visibility: hidden;

	:global(.swiper) {
		width: 100%;
		height: 100%;
		padding-bottom: 32px;
	}

	:global(.swiper-pagination) {
		display: flex;
		justify-content: center;
		bottom: 0;

		:global(.swiper-pagination-bullet) {
			width: 32px;
			height: 2px;
			margin: 0 5px 0 0;
			border-radius: 0;
			background-color: $black10;
			opacity: 1;

			&:hover {
				background-color: $gray;
			}

			&:last-child {
				margin-right: 0;
			}

			&:global(.swiper-pagination-bullet-active) {
				background-color: $navy;
			}
		}
	}

	&.visible {
		visibility: visible;
	}
}
