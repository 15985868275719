@import "~/styles/mixins.scss";
.wrapper {
	.activeMegaWorld,
	.activeWorld {
		background: $black5;
		min-height: 48px;
		align-items: center;
		padding: 10px 28px 10px 40px;
		display: flex;
		width: 100%;
		border-top: 1px solid $black10;
		color: $black55;
		font-size: 14px;
		text-align: left;
		line-height: 22px;
		white-space: break-spaces;
	}

	.activeMegaWorld {
		.icon {
			margin-right: 23px;
		}
	}

	.activeWorld {
		color: $navy;
		justify-content: space-between;

		&:hover {
			text-decoration: underline;
		}
	}
}
