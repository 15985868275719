@import "~/styles/mixins.scss";
.wrapper {
	@include desktop {
		position: absolute;
		left: 286px;
		top: 0;
		width: 512px;
		background: $white;
		border: 1px solid $navy;
	}
}
