@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	font-weight: 700;

	.descriptionContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 16px;
		gap: 8px;
		font-weight: 400;

		.iconWrapper {
			width: 24px;
			height: 24px;
		}

		.description {
			font-size: 12px;
			line-height: 16px;
		}
	}
}
