@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: 48px;
	padding: 16px;
	border-top: 1px solid $black10;
	font-size: 14px;
	color: $black65;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:hover {
		color: $white;
		background-color: $navy;
	}
}
