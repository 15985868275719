@import "~/styles/mixins.scss";
.wrapper {
	padding: 16px 0;
	margin-top: 12px;
	font-size: 12px;
	line-height: 14px;
	color: $black55;

	@include tablet {
		padding: 0 22px;
	}

	.placeholderWrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.buttonWrapper {
		margin: 24px 0;
	}
}
