@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	height: 44px;
	width: 100%;

	&:hover {
		background-color: $black3;
	}

	.button {
		display: flex;
		align-items: center;
		color: $navy;
		gap: 16px;
		padding-left: 40px;

		@include desktop {
			padding-left: 24px;
		}

		&:hover {
			color: $navy;
		}
	}
}
