@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	line-height: 32px;
	margin-bottom: 24px;
	color: $black65;

	&.isMozaic {
		color: $color-grey-800;
	}
}
