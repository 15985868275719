@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.productWrapper {
		min-height: 125px;
	}

	.productDataWrapper {
		position: relative;
		min-height: 73px;
	}

	.productNameAndPriceWrapper {
		display: inline-block;
		max-width: 80%;
	}

	.productPriceWrapper {
		align-items: baseline;
		position: absolute;
		bottom: -16px;
	}

	.productActionsWrapper {
		margin-top: 4px;
		display: block;
	}

	.productActionsTransferButton {
		position: absolute;
		right: 0;
		bottom: -16px;
	}

	.productActionsRemoveButton {
		position: absolute;
		margin-left: 0;
		top: 0;
		right: 0;
	}

	.productPriceSaleWrapper {
		display: flex;
	}
}
