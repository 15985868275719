@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 24px 23px;
	color: $black65;
	background-color: $black5;
	position: relative;

	&.isMozaic {
		color: $color-grey-999;
		background-color: $color-grey-100;
	}

	.caption {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
	}
}
