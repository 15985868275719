@import "~/styles/mixins.scss";
.wrapper {
	position: relative;

	.wishList {
		z-index: 1;
		position: absolute;
		top: 8px;
		right: 8px;
	}
}
