@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.header {
		height: 48px;
		display: flex;
		align-items: center;
		color: $black55;
		font-size: 14px;
		padding-left: 40px;
	}
}
