@import "~/styles/mixins.scss";
.wrapper {
	color: $navy;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	font-size: 12px;
	font-weight: 400;
	height: unset;

	&:hover {
		background: none;
		border: none;
		color: $green;
	}
}
