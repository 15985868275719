@import "~/styles/mixins.scss";
.wrapper {
	width: inherit;
	height: inherit;

	.link {
		display: inherit;
		flex-direction: inherit;
		justify-content: inherit;
		align-items: inherit;
		height: inherit;
		width: inherit;
	}

	&:focus-visible {
		.link {
			@include focus-visible;
		}
	}
}
