@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;

	.content {
		display: flex;
		align-items: flex-start;
		width: 100%;

		@include small-desktop {
			align-items: center;
		}

		.icon {
			margin-left: 20px;
			width: 45px;
			display: flex;
			align-items: center;

			@include small-desktop {
				margin-left: 30px;
			}
		}

		.labelData {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			width: 100%;

			@include small-desktop {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding-right: 80px;
			}
		}
	}

	.additionalContent {
		margin: 0 50px;
		display: flex;
		flex-direction: column;

		.warning {
			margin-top: 24px;
		}

		.description {
			font-size: 14px;
			line-height: 17px;
			max-width: 400px;
			margin-top: 20px;
		}
	}

	&.isMozaic {
		.content .labelData {
			color: $color-grey-600;
		}
	}
}
