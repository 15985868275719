@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 18px;

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 19px;

		&:last-child {
			margin: 0;
		}

		.name {
			text-decoration: underline;
			color: $navy;
			width: 160px;
			padding-right: 8px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.removeButton {
			cursor: pointer;

			&:hover {
				opacity: 0.5;
				transition: opacity .3s ease;
			}
		}
	}
}
