@import "~/styles/mixins.scss";
.wrapper {
	padding: 25px;
	width: 100%;
	overflow-y: auto;

	@include tablet {
		width: 550px;
	}
}
