@import "~/styles/mixins.scss";
.wrapper {
	.description {
		line-height: 20px;
	}

	.control {
		margin-top: 32px;
	}
}
