@import "~/styles/mixins.scss";
.wrapper {
	padding: 14px 16px;
	background: $black5;
	display: flex;

	.title {
		font-size: 16px;
		font-weight: 600;
		color: $gray;
	}
}
