@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include tablet {
		margin-top: 55px;
		flex-direction: row;
	}
}
