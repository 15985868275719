@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid $navy;
	padding: 24px;

	@include tablet {
		padding: 24px 84px;
	}

	&.isMozaic {
		border-color: $color-primary-01-600;
	}
}
