@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin: 55px auto;

	.container {
		display: flex;
		flex-wrap: wrap;

		@include tablet {
			flex-wrap: nowrap;
		}

		.delivery {
			margin-top: 45px;
		}

		.payment {
			margin-top: 25px;
		}

		.delivery,
		.payment {
			@include tablet {
				margin-top: 80px;
			}
		}

		.warning {
			margin-bottom: 41px;
		}
	}
}
