@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 32px;
	min-height: 32px;
	border-radius: 50%;
	font-size: 18px;
	font-weight: 700;
	background-color: $black10;
	color: $black65;

	&.active {
		color: $green;
		border: 1px solid $green;
		background-color: $white;
	}

	&.previous {
		color: $white;
		border: 1px solid $green;
		background-color: $green;
	}

	&.isMozaic {
		background-color: $color-grey-100;
		color: $color-grey-600;

		&.active {
			color: $color-secondary-green-500;
			border-color: $color-secondary-green-500;
			background-color: $white;
		}

		&.previous {
			color: $white;
			border-color: $color-secondary-green-500;
			background-color: $color-secondary-green-500;
		}
	}
}
