@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	display: flex;

	&.withKobi {
		@include tablet {
			margin: unset;
			margin-bottom: 32px;
		}
	}

	.logotype {
		position: absolute;
		min-width: 100px;
		top: -60px;

		@include mobile {
			position: relative;
			top: -20px;
		}

		@include tablet {
			min-width: 120px;
		}

		@include desktop {
			top: -45px;
			left: -30px;
			width: 120px;
		}
	}

	.content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		margin-top: 32px;
		margin-bottom: 32px;

		@include mobile {
			margin-top: 0;
		}

		@include tablet {
			width: auto;
			align-items: center;
			justify-content: flex-start;
			margin: 0 auto 64px;
		}
	}
}
