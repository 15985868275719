@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	background: $white;
	border-radius: 50%;

	.icon {
		position: relative;
		transition: .2s;
	}
}
