@import "~/styles/mixins.scss";
.wrapper {
	max-height: 70vh;
	display: flex;
	flex: 1;
	border: 2px solid $green;
	border-left: none;
	visibility: hidden;

	&.activeMegaWorld {
		visibility: visible;
	}
}
