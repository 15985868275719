@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	margin-top: 24px;

	.iconWrapper {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.content {
		font-size: 12px;
		line-height: 14px;
		color: $black65;
	}

	&.isMozaic {
		align-items: flex-start;
	}
}
