@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin: 2px;

	@include desktop {
		margin-top: 8px;
	}
}
