@import "~/styles/mixins.scss";
.wrapper {
	margin: 16px 0 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px;
	border: 1px solid $navy;
	position: relative;
	color: $black65;
	width: 250px;
	height: 221px;
	transition: border .3s ease;

	@include small-desktop {
		width: 300px;
	}

	&.isMozaic {
		border-radius: 4px;
		border-color: $color-primary-01-600;
		color: $color-grey-800;
		font-size: 14px;

		.check {
			background-color: $color-primary-01-600;
			border-radius: 4px;
		}

		.editButton {
			color: $black;
		}
	}

	.check {
		display: flex;
		position: absolute;
		background-color: $navy;
		justify-content: center;
		align-items: center;
		height: 32px;
		width: 32px;
		top: 0;
		right: 0;
	}

	.editButton {
		align-self: flex-start;
		transition: color .3s ease;
		color: $navy;
		text-decoration: underline;

		&:hover {
			color: $green;
		}
	}
}
