@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include desktop {
		padding: 74px !important;
		min-width: 1120px;
	}

	.content {
		overflow-y: auto;
		height: 100%;

		.actions {
			margin-top: 50px;
			display: flex;
			flex-direction: column;

			@include tablet {
				flex-direction: row;
			}

			.button {
				margin-bottom: 15px;
				width: 100%;

				@include tablet {
					width: auto;
					min-width: 256px;
					margin: 0 15px 30px;
				}
			}
		}
	}
}
