@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include desktop {
		flex-direction: row;
		width: 33%;
		padding: 24px 10px 24px 0;
		flex-shrink: 0;
	}

	.container {
		display: flex;
		position: relative;

		.mobileDotMenu {
			position: absolute;
			right: 16px;

			@include tablet {
				display: none;
			}

			.removeMobileButton {
				white-space: nowrap;
				margin-left: 8px;
			}
		}
	}

	.mobileContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 24px;

		@include desktop {
			display: none;
		}
	}
}
