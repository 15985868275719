@import "~/styles/mixins.scss";
.wrapper {
	display: none;
	width: 100%;
	color: $black65;
	font-weight: 600;
	text-align: center;

	@include desktop {
		display: flex;
		overflow: hidden;
	}

	&.isMozaic {
		color: $color-grey-800;
		font-weight: 400;

		.product,
		.availability,
		.price,
		.quantity,
		.summaryPrice,
		.action {
			border-bottom-color: $color-grey-100;
			color: $black;
			font-size: 14px;
		}
	}

	.product {
		width: 33%;
		text-align: left;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px 0;
		align-items: center;
	}

	.availability {
		width: 21%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.price {
		width: 12%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.quantity {
		width: 18%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.summaryPrice {
		width: 13%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
		justify-content: center;
	}

	.action {
		width: 4%;
		display: flex;
		flex-shrink: 0;
		border-bottom: 1px solid $black10;
		padding: 0 10px 20px;
		align-items: center;
	}
}
