@import "~/styles/mixins.scss";
.wrapper {
	margin-right: 5px;
	z-index: 2;
	height: 100%;
	padding: 16px 14px;
	position: relative;
	align-items: center;
	justify-content: center;
	display: none;

	@include desktop {
		display: flex;
		width: 155px;
	}

	&:hover {
		.label {
			color: $green-dark;
		}

		.icon {
			fill: $green-dark;
		}
	}

	&.active {
		background: $navy;

		.icon {
			fill: $white;
		}

		.label {
			color: $white;

			.arrow {
				transform: rotate(-90deg);
			}
		}

		&:hover {
			.icon {
				fill: $white;
			}

			.label {
				color: $white;
			}
		}
	}

	.label {
		font-size: 12px;
		line-height: 14px;
		color: $navy;
		margin-left: 10px;
		text-align: left;
		transition: color .2s ease;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		white-space: pre;

		.name {
			display: flex;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.arrow {
			max-width: 8px;
			max-height: 8px;
			transform: rotate(90deg);
			margin-top: 17px;
			margin-left: 8px;
		}
	}

	.icon {
		transition: fill .2s ease, transform .2s ease;
	}
}
