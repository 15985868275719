@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	padding: 0 16px;
	width: 257px;

	.registrationWrapper {
		margin-top: 29px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.question {
			font-size: 12px;
			line-height: 16px;
			color: $black55;
		}

		.button {
			width: 132px;
		}
	}
}
