@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	width: 100%;
	padding-top: 16px;
	border-top: 1px solid $black10;

	.button {
		display: flex;
		justify-content: space-between;
		width: 100%;
		color: $navy;
		font-weight: 700;
		line-height: 17px;

		&.isMozaic {
			color: $black;
		}

		.icon {
			transform: rotate(90deg);

			&.isActive {
				transform: rotate(-90deg);
			}

			&.isMozaic {
				fill: $black;
			}
		}
	}
}
