@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;
	max-width: 100%;
	position: relative;

	@include tablet {
		margin-top: 16px;
	}

	.loader {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		z-index: 2;
	}

	.swiper {
		width: 100%;
		max-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		min-height: 0;
		min-width: 0;

		.slide {
			width: 104px;
			height: 40px;

			&.loading {
				pointer-events: none;
			}
		}
	}
}
