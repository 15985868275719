@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 16px 0;

	&.firstElement {
		border-top: 1px solid $black10;
	}

	&.isMozaic {
		border-color: $color-grey-500;
		color: $color-grey-800;
	}
}
