@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;
	position: relative;

	.dropdown {
		width: 100%;
		max-height: 185px;
		border: 1px solid $black10;
		border-top-width: 0;
		background-color: $white;
		overflow-y: scroll;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;

		.items {
			.item {
				padding: 8px 16px;
				background-color: $white;
				cursor: pointer;

				&:hover {
					background-color: $black3;
				}
			}
		}
	}
}
