@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	flex-basis: 320px;

	@include tablet {
		flex-basis: auto;
	}

	.label {
		color: $black65;
		margin-left: 16px;
		line-height: 17px;
	}
}
