@import "~/styles/mixins.scss";
.wrapper {
	height: 64px !important;
	margin-top: 16px;
	font-size: 14px;
	line-height: 16px;
	color: $navy;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;

	@include tablet {
		height: 50px !important;
	}

	&:hover {
		text-decoration: underline;
	}

	&:focus-visible {
		@include focus-visible;
	}

	.link {
		display: contents;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
	}
}
