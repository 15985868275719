@import "~/styles/mixins.scss";
.wrapper {
	background: $gray95;
	display: flex;
	align-items: center;
	padding: 16px;
	margin-top: 12px;

	.icon {
		width: 25px;
		height: 25px;
	}

	.text {
		margin-left: 16px;

		.caption {
			line-height: 18px;
			font-weight: 700;
			color: $black65;
		}

		.description {
			margin-top: 8px;

			.buttonLink {
				display: inline;
				color: $navy;
				line-height: 18px;

				&:hover {
					color: $green;
				}
			}

			.info {
				line-height: 18px;
			}
		}
	}
}
