@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-top: 24px;
	border-top: 1px solid $black10;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	&.isMozaic {
		border-color: $color-grey-500;
	}
}
