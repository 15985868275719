@import "~/styles/mixins.scss";
.wrapper {
	position: absolute;
	right: 0;
	top: 24px;

	@include desktop {
		position: unset;
		padding: 24px 10px;
		width: 4%;
		justify-content: center;
		display: flex;
		flex-shrink: 0;
	}

	.button {
		padding: 5px;

		&:hover {
			.icon {
				fill: $red;
			}
		}

		.icon {
			transition: fill .2s ease;
		}
	}
}
