@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;
	padding-top: 16px;
	border-top: 1px solid $black10;
	width: 100%;

	&.isMozaic {
		border-color: $color-grey-500;
		color: $color-grey-800;
	}

	.row {
		display: flex;
		justify-content: space-between;

		& + .row {
			margin-top: 16px;
		}
	}
}
