@import "~/styles/mixins.scss";
.wrapper {
	overflow-y: auto;

	@include tablet {
		max-width: 561px;
	}

	.form {
		margin-top: 40px;

		@include tablet {
			margin-top: 32px;
		}
	}

	.content {
		margin-top: 32px;

		@include tablet {
			margin-top: 40px;
		}
	}
}
