@import "~/styles/mixins.scss";
.container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	max-width: 100%;
	padding: 0 16px;

	@include large-desktop {
		width: $view-size-large-desktop;
		padding: 0;
	}
}
