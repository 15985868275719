@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	.label {
		margin-bottom: 10px;
	}

	.loaderWrapper {
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;

		.loader {
			position: relative;

			&,
			&:before,
			&:after {
				width: 100%;
				height: 100%;
			}

			&:before,
			&:after {
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				content: "";
			}

			&:before {
				border-top: 1.5px solid $black10;
				border-right: 1.5px solid $black10;
				border-bottom: 1.5px solid $black10;
				border-left: 1.5px solid $green;
				z-index: 10;
				animation: spin-animation 2s ease-in-out infinite;
			}

			&:after {
				border-top: 1.5px solid transparent;
				border-right: 1.5px solid transparent;
				border-bottom: 1.5px solid transparent;
				border-left: 1.5px solid transparent;
				z-index: 15;
				animation: spin-animation 1s ease-in-out infinite;
			}
		}
	}

	.fullPage {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;

		&.withOpacity {
			background-color: $black5;
		}

		.loaderWrapper {
			width: 50px;
			height: 50px;
		}
	}

	@keyframes spin-animation {
		0% {
			transform: rotateZ(0deg);
		}

		100% {
			transform: rotateZ(360deg);
		}
	}
}

.pagesConfigLoader {
	min-height: 700px;
	display: flex;
	align-items: center;
	justify-content: center;
}
