@import "~/styles/mixins.scss";
.wrapper {
	line-height: 16px;
	font-size: 12px;

	&:before {
		content: "/";
		margin-left: 6px;
		color: $navy;
	}

	&.isMozaic {
		&:before {
			content: none;
		}

		.icon {
			margin-left: 5px;
		}
	}

	.label {
		margin-left: 6px;
		color: $navy;
		transition: color .2s ease;

		&:not(.isLast):hover {
			color: $green-dark;
		}

		&.isLast {
			color: $black55;
			font-weight: 700;
		}

		&.isMozaic {
			margin-left: auto;
			padding: 0 !important;
			color: $black;
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
		}
	}
}
