@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-left: 16px;

	.block {
		display: flex;
		flex-direction: column;
		width: 192px;
		margin-right: 16px;

		.link {
			color: $navy;
			margin-bottom: 4px;

			&:hover {
				text-decoration: underline;
			}
		}

		.unit {
			font-size: 12px;
			color: $black55;
		}
	}

	.productPrice {
		margin-top: 15px;
	}
}
