@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;

	.filesPicker {
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s ease;
		white-space: nowrap;
		height: 40px;
		cursor: pointer;

		&.primary {
			background: $green;
			border: 2px solid $green;
			color: $white;

			&:hover {
				background: $green-dark;
				border: 2px solid $green-dark;
			}

			&.reverse {
				background: transparent;
				color: $green;

				&:hover {
					background: $green-dark;
					border: 2px solid $green-dark;
					color: $white;
				}
			}
		}

		&.secondary {
			background: $navy;
			border: 2px solid $navy;
			color: $white;

			&:hover {
				background: $navy-dark;
				border: 2px solid $navy-dark;
			}

			&.reverse {
				background: transparent;
				color: $navy;

				&:hover {
					background: $navy;
					border: 2px solid $navy;
					color: $white;
				}
			}
		}

		.input[type="file"] {
			display: none;
		}
	}

	.description {
		font-size: 11px;
		margin-top: 7px;
	}
}
