@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	&:first-child {
		margin-top: 0;
	}

	.caption {
		display: flex;
		align-items: center;

		.text {
			font-weight: 600;
		}

		.tooltip {
			margin-left: 10px;
		}
	}

	.description {
		margin-top: 7px;
		font-size: 12px;
		line-height: 14px;
		color: $black65;
	}

	.label {
		font-size: 12px;
		color: $black65;

		a {
			color: $navy !important;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.consentsBox {
		font-size: 12px;
		margin-left: 45px;

		.consentsAdditionalContent {
			margin-top: 20px;

			a {
				color: $navy !important;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.privacy {
		margin-top: 16px;
	}
}
