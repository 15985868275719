@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;

	&.small {
		.base {
			line-height: 14px;

			.price {
				font-size: 16px;
			}
		}

		.sale {
			.strikethrough {
				line-height: 14px;
				font-size: 12px;
				color: $black65;
			}
		}
	}

	&.big {
		.base {
			line-height: 24px;

			.price {
				font-size: 32px;

				@include tablet {
					font-size: 40px;
				}
			}

			.unit {
				font-size: 18px;

				@include tablet {
					font-size: 24px;
				}
			}
		}

		.sale {
			.strikethrough {
				line-height: 24px;
				font-size: 18px;
				color: $black65;
			}

			.label {
				font-size: 18px;
				margin-left: 10px;
			}
		}
	}

	.base {
		line-height: 18px;

		&.promotional {
			color: $red;
		}

		.price {
			font-size: 20px;
			font-weight: 600;
		}

		.unit {
			margin-left: 3px;
			font-size: 14px;
		}
	}

	.sale {
		line-height: 18px;
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;

		.strikethrough {
			line-height: 18px;
			font-size: 14px;
			text-decoration: line-through;
			color: $black65;
		}

		.label {
			margin-left: 5px;
			font-size: 12px;
			color: $red;

			@include medium-mobile {
				margin-left: 0;
			}

			&.omnibusPrice {
				color: $black65;
			}
		}
	}
}
