@import "~/styles/mixins.scss";
.wrapper {
	.title {
		font-weight: 700;
	}

	.action {
		color: $navy;
		text-decoration: underline;
		display: inline-block;
	}
}
