@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 24px;

	@include tablet {
		max-width: 400px;
		display: flex;
	}

	.button {
		width: 100%;

		@include tablet {
			width: 192px;

			&:last-child {
				margin-left: 17px;
			}
		}

		& + .button {
			margin-top: 24px;

			@include tablet {
				margin-top: 0;
			}
		}
	}
}
