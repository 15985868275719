.react-select {
	& & {
		&__value-container {
			padding: 2px 16px;
		}

		&__control {
			height: 48px;
			border: 1px solid $black10;
			border-radius: 0;
			cursor: pointer;

			&:hover {
				border-color: $black10;
			}

			&--is-disabled {
				border-color: $black5;
				background-color: $black5;
			}

			&--is-focused {
				border-color: $black10;

				@include focus-visible; // TODO: add focus-visible when it will be possible in react-select

				&:hover {
					box-shadow: none;
				}
			}

			&--menu-is-open {
				box-shadow: none;

				.react-select__indicator {
					transform: rotate(180deg);
					transition: transform .2s ease;
				}
			}
		}

		&__indicator {
			color: $green;
		}

		&__option {
			padding: 16px;
			cursor: pointer;

			@include tablet {
				padding: 12px 16px;
			}

			&:focus-visible {
				outline: none;
			}
		}

		&__placeholder,
		&__input-container,
		&__single-value,
		&__option {
			color: $black55;
			font-size: 14px;
			font-weight: 400;
		}

		&__single-value {
			overflow: unset;
		}

		&__placeholder {
			color: $black30;
		}

		&__menu-list {
			padding-top: 0;
			padding-bottom: 0;
		}

		&__menu {
			margin-top: 0;
			margin-bottom: 0;
			border: 1px solid $black10;
			border-radius: 0;
			box-shadow: none;
		}

		&__option--is-focused {
			background-color: $white;

			@include small-desktop {
				background-color: $gray95;
			}
		}

		&__menu-notice--no-options {
			padding: 16px;
			color: $black40;
		}
	}
}
