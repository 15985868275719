@import "~/styles/mixins.scss";
.wrapper {
	display: flex;

	@include small-desktop {
		width: 200px;
	}

	@include desktop {
		width: 300px;
	}

	.caption {
		margin-right: 12px;
	}
}
